.template-reel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1080px;
  height: 1920px;
  background: var(--theme-color) url('./Asset/Image/Background.png') no-repeat
    center center;
  padding: 86px;

  &[data-index='0'] {
    h1 {
      margin: 40px 0;
      font-size: 120px;
      line-height: 1.3;
    }
  }

  &:nth-child(n + 2) {
    margin-top: 40px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-logo {
      width: 96px;
      height: 58px;
    }

    &-sound {
      width: 72px;
      height: 72px;
    }
  }

  &-body {
    max-height: 1920px;
    overflow: hidden;

    &-section {
      transition: all linear 0.5s;
    }

    font-family: 'Helvetica Neue';
    font-size: 40px;
    line-height: 1.5;

    h1 {
      font-family: Impact;
      font-weight: normal;
      font-size: 84px;

      > strong {
        font-weight: normal;
      }
    }

    h2 {
      margin-top: 36px;
      margin-bottom: 28px;
      display: inline-block;
      border: 3px solid #111;
      padding: 10px 20px;
      font-size: 48px;
      font-family: 'Impact';
      font-weight: normal;
    }

    h3 {
      margin-top: 24px;
      display: inline-block;
      border: 3px solid #111;
      padding: 4px 16px;
      font-size: 36px;
      font-family: 'Impact';
      font-weight: normal;

      + * {
        margin-top: 0;
      }
    }

    p {
      margin-top: 24px;
    }

    em {
      opacity: 0.6;
      font-style: italic;
    }

    strong {
      font-weight: bold;
      color: var(--primary-color);
    }

    img {
      display: block;
      width: 100%;
    }

    ol {
      margin-top: 24px;
      padding-left: 1.2em;
      list-style-type: decimal;

      > li {
        &:nth-child(n + 2) {
          margin-top: 12px;
        }

        &::marker {
          color: var(--theme-color);
        }
      }
    }

    ul {
      margin-top: 24px;
      padding-left: 1em;
      list-style-type: initial;

      > li {
        &::marker {
          color: var(--theme-color);
        }
      }
    }

    pre {
      margin-top: 24px;
    }
  }

  &-footer {
    margin-top: 36px;
    border-top: 2px solid #ddd;
    padding-top: 32px;
    font-style: italic;
    color: #666;
    font-size: 30px;
    opacity: 0;
  }
}
