.catalog-post {
  &-body {
    > * {
      display: none;
    }

    > h1 {
      display: block;
      font-weight: bold;
    }

    > p {
      display: block;
    }

    strong {
      font-weight: inherit;
    }

    img {
      display: none;
    }
  }
}
