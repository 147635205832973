@font-face {
  font-family: Impact;
  src: url('./Asset/Font/Impact.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./Asset/Font/HelveticaNeue.woff') format('woff');
}

:root {
  --theme-color: #ffa700;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  background-color: #eee;
  font-family: sans-serif;
}

[contenteditable],
button,
label,
input,
textarea,
select {
  outline: 0;
}

select {
  appearance: none;
  display: inline-block;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 1em;
}

blockquote {
  display: none;
}

label.icon-button,
button.icon-button {
  appearance: none;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  width: 44px;
  height: 44px;
  cursor: default;
}

label.icon-button input {
  display: none;
}

.animation-fade-in {
  animation: fadeIn linear 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
