.excalidraw {
  label[title='Library'] {
    display: none;
  }

  &-stage-ratio {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    border: 1px dashed #999;
  }
}
