.template-carousell {
  position: relative;
  width: 1080px;
  height: 1350px;
  font-family: 'Roboto';
  background-color: var(--theme-color);
  color: #fff;

  &:nth-child(n + 2) {
    margin-top: 40px;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: -20px;
    right: 160px;
    z-index: 0;
    display: block;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    background-color: rgba($color: #ffffff, $alpha: 0.03);
    transform: scale(360%);
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: -50px;
    z-index: 0;
    display: block;
    border-radius: 100%;
    width: 100px;
    height: 100px;
    background-color: rgba($color: #ffffff, $alpha: 0.03);
    transform: scale(400%);
  }

  &-container {
    background-color: #343434;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 60px 80px 20px;
  }

  &-header {
    background: url('./Asset/Image/Logo.png') no-repeat;
    height: 68px;
    line-height: 68px;
    text-align: right;
    font-size: 36px;
    font-style: italic;
    color: #ccc;
  }

  &-body {
    margin: 60px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    &-section {
      transition: all linear 0.5s;
    }

    font-size: 40px;
    line-height: 1.7;

    h1 {
      margin-bottom: 60px;
      font-size: 108px;
      line-height: 1.3;
      font-weight: bold;
    }

    h2 {
      margin-bottom: 40px;
      font-size: 64px;
      font-weight: bold;
      // color: var(--primary-color);

      &:after {
        margin-top: 12px;
        content: ' ';
        display: block;
        width: 128px;
        height: 8px;
        background-color: #fff;
      }
    }

    h3 {
      margin-top: 24px;
      display: inline-block;
      border: 3px solid #fff;
      padding: 4px 16px;
      font-size: 36px;
      font-family: 'Impact';
      font-weight: normal;

      + * {
        margin-top: 0;
      }
    }

    p {
      margin-top: 24px;
    }

    em {
      opacity: 0.6;
      font-style: italic;
    }

    strong {
      font-weight: bold;
      color: var(--primary-color);
    }

    img {
      border-radius: 20px;
      display: block;
      width: 100%;
    }

    ol {
      margin-top: 24px;
      padding-left: 1.2em;
      list-style-type: decimal;

      > li {
        &:nth-child(n + 2) {
          margin-top: 12px;
        }

        &::marker {
          color: var(--primary-color);
        }
      }
    }

    ul {
      margin-top: 24px;
      padding-left: 1em;
      list-style-type: initial;

      > li {
        &::marker {
          color: var(--primary-color);
        }
      }
    }

    pre {
      margin-top: 24px;
    }
  }

  &-footer {
    border-top: 4px dotted rgba($color: #ffffff, $alpha: 0.4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;

    &-progress {
      flex: 1;
      display: flex;
      align-items: center;

      &-bar {
        margin-right: 4px;
        display: inline-block;
        width: 12px;
        height: 32px;
        background-color: rgba($color: #ffffff, $alpha: 0.4);

        &.active {
          background-color: var(--primary-color);
          height: 40px;
        }
      }
    }

    &-url {
      display: flex;
      align-items: center;
      font-size: 36px;
      line-height: 48px;

      &-domain {
        font-style: italic;
        color: rgba($color: #ffffff, $alpha: 0.6);
      }

      &-number {
        margin-left: 8px;
        display: inline-block;
        border-radius: 4px;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        width: 32px;
        height: 48px;

        text-align: center;
      }
    }
  }
}
