.markdown-display-monospace {
  vertical-align: 2px;
  color: #fff;
  border: 4px solid #ccc;
  border-radius: 8px;
  padding: 8px 12px;
}

.markdown-display-code {
  border-radius: 8px !important;
  padding: 12px 20px !important;
  font-size: 30px !important;
  line-height: 1.8 !important;
}

.template-default-first {
  --primary-color: orange;
  background: var(--theme-color) center center no-repeat;
  width: 1080px;
  height: 1080px;
  justify-content: center;
  align-items: center;
  padding: 68px 84px;
  display: flex;
}

.template-default-first-content {
  color: #fff;
  font-family: Helvetica Neue;
  font-size: 36px;
  line-height: 64px;
}

.template-default-first-content h1 {
  font-family: Impact;
  font-size: 100px;
  font-weight: normal;
  line-height: 1.5;
}

.template-default-first-content h1 strong {
  color: var(--primary-color);
  font-weight: normal;
}

.template-default-first-content img {
  width: 100%;
  display: block;
}

.template-default-middle {
  --primary-color: orange;
  width: 1080px;
  height: 1080px;
  background-color: #fff;
  align-items: stretch;
  padding: 68px 32px 68px 68px;
  display: flex;
}

.template-default-middle-body {
  flex: 1;
  margin-right: 68px;
  overflow: hidden;
}

.template-default-middle-body-logo {
  width: 96px;
  height: 58px;
}

.template-default-middle-body-content {
  font-family: Helvetica Neue;
  font-size: 40px;
  line-height: 64px;
}

.template-default-middle-body-content > * {
  margin-top: 36px;
}

.template-default-middle-body-content h2 {
  border: 1px solid #000;
  margin: 64px 0;
  padding: 10px 20px;
  font-family: Impact;
  font-size: 48px;
  font-weight: normal;
  display: inline-block;
  box-shadow: 0 4px 4px #00000026;
}

.template-default-middle-body-content h2 + * {
  margin-top: 0;
}

.template-default-middle-body-content h3 {
  border: 1px solid #000;
  margin: 48px 0;
  padding: 4px 16px;
  font-family: Impact;
  font-size: 36px;
  font-weight: normal;
  display: inline-block;
  box-shadow: 0 4px 4px #00000026;
}

.template-default-middle-body-content h3 + * {
  margin-top: 0;
}

.template-default-middle-body-content em {
  opacity: .6;
  font-style: italic;
}

.template-default-middle-body-content strong {
  color: var(--primary-color);
  font-weight: bold;
}

.template-default-middle-body-content img {
  width: 100%;
  display: block;
}

.template-default-middle-body-content ol {
  padding-left: 1.2em;
  list-style-type: decimal;
}

.template-default-middle-body-content ol > li:nth-child(n+2) {
  margin-top: 12px;
}

.template-default-middle-body-content ol > li::marker {
  color: var(--theme-color);
}

.template-default-middle-body-content ul {
  list-style-type: initial;
  padding-left: 1em;
}

.template-default-middle-body-content ul > li::marker {
  color: var(--theme-color);
}

.template-default-middle-header {
  opacity: .4;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.template-default-middle-header-progress {
  border: 1px solid var(--theme-color);
  width: 8px;
  flex: 1;
}

.template-default-middle-header-progress-scroller {
  background-color: var(--theme-color);
}

.template-default-middle-header-pager {
  color: var(--theme-color);
  margin-top: 34px;
  font-family: Impact;
  font-size: 48px;
  font-weight: normal;
}

.template-default-last {
  --primary-color: orange;
  background: var(--theme-color) center center no-repeat;
  width: 1080px;
  height: 1080px;
  justify-content: center;
  align-items: center;
  padding: 68px 136px;
  display: flex;
}

.template-default-slides > :nth-child(n+2) {
  margin-top: 40px;
}

.template-reel {
  width: 1080px;
  height: 1920px;
  background: var(--theme-color) url("Background.122f6310.png") no-repeat center center;
  flex-direction: column;
  justify-content: center;
  padding: 86px;
  display: flex;
}

.template-reel[data-index="0"] h1 {
  margin: 40px 0;
  font-size: 120px;
  line-height: 1.3;
}

.template-reel:nth-child(n+2) {
  margin-top: 40px;
}

.template-reel-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.template-reel-header-logo {
  width: 96px;
  height: 58px;
}

.template-reel-header-sound {
  width: 72px;
  height: 72px;
}

.template-reel-body {
  max-height: 1920px;
  font-family: Helvetica Neue;
  font-size: 40px;
  line-height: 1.5;
  overflow: hidden;
}

.template-reel-body-section {
  transition: all .5s linear;
}

.template-reel-body h1 {
  font-family: Impact;
  font-size: 84px;
  font-weight: normal;
}

.template-reel-body h1 > strong {
  font-weight: normal;
}

.template-reel-body h2 {
  border: 3px solid #111;
  margin-top: 36px;
  margin-bottom: 28px;
  padding: 10px 20px;
  font-family: Impact;
  font-size: 48px;
  font-weight: normal;
  display: inline-block;
}

.template-reel-body h3 {
  border: 3px solid #111;
  margin-top: 24px;
  padding: 4px 16px;
  font-family: Impact;
  font-size: 36px;
  font-weight: normal;
  display: inline-block;
}

.template-reel-body h3 + * {
  margin-top: 0;
}

.template-reel-body p {
  margin-top: 24px;
}

.template-reel-body em {
  opacity: .6;
  font-style: italic;
}

.template-reel-body strong {
  color: var(--primary-color);
  font-weight: bold;
}

.template-reel-body img {
  width: 100%;
  display: block;
}

.template-reel-body ol {
  margin-top: 24px;
  padding-left: 1.2em;
  list-style-type: decimal;
}

.template-reel-body ol > li:nth-child(n+2) {
  margin-top: 12px;
}

.template-reel-body ol > li::marker {
  color: var(--theme-color);
}

.template-reel-body ul {
  list-style-type: initial;
  margin-top: 24px;
  padding-left: 1em;
}

.template-reel-body ul > li::marker {
  color: var(--theme-color);
}

.template-reel-body pre {
  margin-top: 24px;
}

.template-reel-footer {
  color: #666;
  opacity: 0;
  border-top: 2px solid #ddd;
  margin-top: 36px;
  padding-top: 32px;
  font-size: 30px;
  font-style: italic;
}

.browser-modal {
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.browser-modal-container {
  width: 95%;
  height: 95%;
}









.template-carousell {
  width: 1080px;
  height: 1350px;
  background-color: var(--theme-color);
  color: #fff;
  font-family: Roboto;
  position: relative;
}

.template-carousell:nth-child(n+2) {
  margin-top: 40px;
}

.template-carousell:before {
  content: " ";
  z-index: 0;
  width: 100px;
  height: 100px;
  background-color: #ffffff08;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: -20px;
  right: 160px;
  transform: scale(3.6);
}

.template-carousell:after {
  content: " ";
  z-index: 0;
  width: 100px;
  height: 100px;
  background-color: #ffffff08;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: scale(4);
}

.template-carousell-container {
  height: 100%;
  background-color: #343434;
  flex-direction: column;
  padding: 60px 80px 20px;
  display: flex;
}

.template-carousell-header {
  height: 68px;
  text-align: right;
  color: #ccc;
  background: url("logo.293fa8dc.png") no-repeat;
  font-size: 36px;
  font-style: italic;
  line-height: 68px;
}

.template-carousell-body {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin: 60px 0;
  font-size: 40px;
  line-height: 1.7;
  display: flex;
  overflow: hidden;
}

.template-carousell-body-section {
  transition: all .5s linear;
}

.template-carousell-body h1 {
  margin-bottom: 60px;
  font-size: 108px;
  font-weight: bold;
  line-height: 1.3;
}

.template-carousell-body h2 {
  margin-bottom: 40px;
  font-size: 64px;
  font-weight: bold;
}

.template-carousell-body h2:after {
  content: " ";
  width: 128px;
  height: 8px;
  background-color: #fff;
  margin-top: 12px;
  display: block;
}

.template-carousell-body h3 {
  border: 3px solid #fff;
  margin-top: 24px;
  padding: 4px 16px;
  font-family: Impact;
  font-size: 36px;
  font-weight: normal;
  display: inline-block;
}

.template-carousell-body h3 + * {
  margin-top: 0;
}

.template-carousell-body p {
  margin-top: 24px;
}

.template-carousell-body em {
  opacity: .6;
  font-style: italic;
}

.template-carousell-body strong {
  color: var(--primary-color);
  font-weight: bold;
}

.template-carousell-body img {
  width: 100%;
  border-radius: 20px;
  display: block;
}

.template-carousell-body ol {
  margin-top: 24px;
  padding-left: 1.2em;
  list-style-type: decimal;
}

.template-carousell-body ol > li:nth-child(n+2) {
  margin-top: 12px;
}

.template-carousell-body ol > li::marker {
  color: var(--primary-color);
}

.template-carousell-body ul {
  list-style-type: initial;
  margin-top: 24px;
  padding-left: 1em;
}

.template-carousell-body ul > li::marker {
  color: var(--primary-color);
}

.template-carousell-body pre {
  margin-top: 24px;
}

.template-carousell-footer {
  border-top: 4px dotted #fff6;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  display: flex;
}

.template-carousell-footer-progress {
  flex: 1;
  align-items: center;
  display: flex;
}

.template-carousell-footer-progress-bar {
  width: 12px;
  height: 32px;
  background-color: #fff6;
  margin-right: 4px;
  display: inline-block;
}

.template-carousell-footer-progress-bar.active {
  background-color: var(--primary-color);
  height: 40px;
}

.template-carousell-footer-url {
  align-items: center;
  font-size: 36px;
  line-height: 48px;
  display: flex;
}

.template-carousell-footer-url-domain {
  color: #fff9;
  font-style: italic;
}

.template-carousell-footer-url-number {
  width: 32px;
  height: 48px;
  text-align: center;
  background-color: #fff3;
  border-radius: 4px;
  margin-left: 8px;
  display: inline-block;
}

.catalog-post-body > * {
  display: none;
}

.catalog-post-body > h1 {
  font-weight: bold;
  display: block;
}

.catalog-post-body > p {
  display: block;
}

.catalog-post-body strong {
  font-weight: inherit;
}

.catalog-post-body img {
  display: none;
}

.catalog-sidebar::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: Impact;
  src: url("Impact.a1a89281.woff") format("woff");
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue.22bb5bce.woff") format("woff");
}

:root {
  --theme-color: #ffa700;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  background-color: #eee;
  font-family: sans-serif;
}

[contenteditable], button, label, input, textarea, select {
  outline: 0;
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 1em;
  display: inline-block;
}

blockquote {
  display: none;
}

label.icon-button, button.icon-button {
  appearance: none;
  width: 44px;
  height: 44px;
  cursor: default;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  display: inline-block;
}

label.icon-button input {
  display: none;
}

.animation-fade-in {
  animation: .5s linear fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-5%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.z-50 {
  z-index: 50;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.h-full {
  height: 100%;
}

.h-16 {
  height: 4rem;
}

.h-8 {
  height: 2rem;
}

.h-12 {
  height: 3rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-10 {
  height: 2.5rem;
}

.h-48 {
  height: 12rem;
}

.w-full {
  width: 100%;
}

.w-52 {
  width: 13rem;
}

.w-20 {
  width: 5rem;
}

.w-64 {
  width: 16rem;
}

.w-48 {
  width: 12rem;
}

.w-12 {
  width: 3rem;
}

.w-24 {
  width: 6rem;
}

.w-16 {
  width: 4rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.flex-1 {
  flex: 1;
}

.cursor-default {
  cursor: default;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-cover {
  background-size: cover;
}

.p-4 {
  padding: 1rem;
}

.p-3 {
  padding: .75rem;
}

.p-2 {
  padding: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.text-center {
  text-align: center;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-orange-700 {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

@font-face {
  font-family: feather;
  src: url("Feather.ce8dfd7c.ttf") format("truetype"), url("Feather.c4207d01.woff") format("woff"), url("Feather.8e2c5556.svg#feather") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: feather !important;
}

.icon-activity:before {
  content: "";
}

.icon-airplay:before {
  content: "";
}

.icon-alert-circle:before {
  content: "";
}

.icon-alert-octagon:before {
  content: "";
}

.icon-alert-triangle:before {
  content: "";
}

.icon-align-center:before {
  content: "";
}

.icon-align-justify:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-align-right:before {
  content: "";
}

.icon-anchor:before {
  content: "";
}

.icon-aperture:before {
  content: "";
}

.icon-archive:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-down-circle:before {
  content: "";
}

.icon-arrow-down-left:before {
  content: "";
}

.icon-arrow-down-right:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-left-circle:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-right-circle:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-arrow-up-circle:before {
  content: "";
}

.icon-arrow-up-left:before {
  content: "";
}

.icon-arrow-up-right:before {
  content: "";
}

.icon-at-sign:before {
  content: "";
}

.icon-award:before {
  content: "";
}

.icon-bar-chart:before {
  content: "";
}

.icon-bar-chart-2:before {
  content: "";
}

.icon-battery:before {
  content: "";
}

.icon-battery-charging:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-bell-off:before {
  content: "";
}

.icon-bluetooth:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-book-open:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-box:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-camera-off:before {
  content: "";
}

.icon-cast:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-check-square:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-chevrons-down:before {
  content: "";
}

.icon-chevrons-left:before {
  content: "";
}

.icon-chevrons-right:before {
  content: "";
}

.icon-chevrons-up:before {
  content: "";
}

.icon-chrome:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-clipboard:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-cloud-drizzle:before {
  content: "";
}

.icon-cloud-lightning:before {
  content: "";
}

.icon-cloud-off:before {
  content: "";
}

.icon-cloud-rain:before {
  content: "";
}

.icon-cloud-snow:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-codepen:before {
  content: "";
}

.icon-codesandbox:before {
  content: "";
}

.icon-coffee:before {
  content: "";
}

.icon-columns:before {
  content: "";
}

.icon-command:before {
  content: "";
}

.icon-compass:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-corner-down-left:before {
  content: "";
}

.icon-corner-down-right:before {
  content: "";
}

.icon-corner-left-down:before {
  content: "";
}

.icon-corner-left-up:before {
  content: "";
}

.icon-corner-right-down:before {
  content: "";
}

.icon-corner-right-up:before {
  content: "";
}

.icon-corner-up-left:before {
  content: "";
}

.icon-corner-up-right:before {
  content: "";
}

.icon-cpu:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-crop:before {
  content: "";
}

.icon-crosshair:before {
  content: "";
}

.icon-database:before {
  content: "";
}

.icon-delete:before {
  content: "";
}

.icon-disc:before {
  content: "";
}

.icon-dollar-sign:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-download-cloud:before {
  content: "";
}

.icon-droplet:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-edit-2:before {
  content: "";
}

.icon-edit-3:before {
  content: "";
}

.icon-external-link:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-eye-off:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-fast-forward:before {
  content: "";
}

.icon-feather:before {
  content: "";
}

.icon-figma:before {
  content: "";
}

.icon-file:before {
  content: "";
}

.icon-file-minus:before {
  content: "";
}

.icon-file-plus:before {
  content: "";
}

.icon-file-text:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-folder-minus:before {
  content: "";
}

.icon-folder-plus:before {
  content: "";
}

.icon-framer:before {
  content: "";
}

.icon-frown:before {
  content: "";
}

.icon-gift:before {
  content: "";
}

.icon-git-branch:before {
  content: "";
}

.icon-git-commit:before {
  content: "";
}

.icon-git-merge:before {
  content: "";
}

.icon-git-pull-request:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-gitlab:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-hard-drive:before {
  content: "";
}

.icon-hash:before {
  content: "";
}

.icon-headphones:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-help-circle:before {
  content: "";
}

.icon-hexagon:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-italic:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-layers:before {
  content: "";
}

.icon-layout:before {
  content: "";
}

.icon-life-buoy:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-link-2:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-loader:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-log-in:before {
  content: "";
}

.icon-log-out:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-map:before {
  content: "";
}

.icon-map-pin:before {
  content: "";
}

.icon-maximize:before {
  content: "";
}

.icon-maximize-2:before {
  content: "";
}

.icon-meh:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-message-circle:before {
  content: "";
}

.icon-message-square:before {
  content: "";
}

.icon-mic:before {
  content: "";
}

.icon-mic-off:before {
  content: "";
}

.icon-minimize:before {
  content: "";
}

.icon-minimize-2:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-minus-circle:before {
  content: "";
}

.icon-minus-square:before {
  content: "";
}

.icon-monitor:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-more-horizontal:before {
  content: "";
}

.icon-more-vertical:before {
  content: "";
}

.icon-mouse-pointer:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-music:before {
  content: "";
}

.icon-navigation:before {
  content: "";
}

.icon-navigation-2:before {
  content: "";
}

.icon-octagon:before {
  content: "";
}

.icon-package:before {
  content: "";
}

.icon-paperclip:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-pause-circle:before {
  content: "";
}

.icon-pen-tool:before {
  content: "";
}

.icon-percent:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-phone-call:before {
  content: "";
}

.icon-phone-forwarded:before {
  content: "";
}

.icon-phone-incoming:before {
  content: "";
}

.icon-phone-missed:before {
  content: "";
}

.icon-phone-off:before {
  content: "";
}

.icon-phone-outgoing:before {
  content: "";
}

.icon-pie-chart:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-play-circle:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-plus-circle:before {
  content: "";
}

.icon-plus-square:before {
  content: "";
}

.icon-pocket:before {
  content: "";
}

.icon-power:before {
  content: "";
}

.icon-printer:before {
  content: "";
}

.icon-radio:before {
  content: "";
}

.icon-refresh-ccw:before {
  content: "";
}

.icon-refresh-cw:before {
  content: "";
}

.icon-repeat:before {
  content: "";
}

.icon-rewind:before {
  content: "";
}

.icon-rotate-ccw:before {
  content: "";
}

.icon-rotate-cw:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-scissors:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-send:before {
  content: "";
}

.icon-server:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-share-2:before {
  content: "";
}

.icon-shield:before {
  content: "";
}

.icon-shield-off:before {
  content: "";
}

.icon-shopping-bag:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

.icon-shuffle:before {
  content: "";
}

.icon-sidebar:before {
  content: "";
}

.icon-skip-back:before {
  content: "";
}

.icon-skip-forward:before {
  content: "";
}

.icon-slack:before {
  content: "";
}

.icon-slash:before {
  content: "";
}

.icon-sliders:before {
  content: "";
}

.icon-smartphone:before {
  content: "";
}

.icon-smile:before {
  content: "";
}

.icon-speaker:before {
  content: "";
}

.icon-square:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-stop-circle:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-sunrise:before {
  content: "";
}

.icon-sunset:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-target:before {
  content: "";
}

.icon-terminal:before {
  content: "";
}

.icon-thermometer:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-toggle-left:before {
  content: "";
}

.icon-toggle-right:before {
  content: "";
}

.icon-tool:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-trash-2:before {
  content: "";
}

.icon-trello:before {
  content: "";
}

.icon-trending-down:before {
  content: "";
}

.icon-trending-up:before {
  content: "";
}

.icon-triangle:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-tv:before {
  content: "";
}

.icon-twitch:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-type:before {
  content: "";
}

.icon-umbrella:before {
  content: "";
}

.icon-underline:before {
  content: "";
}

.icon-unlock:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-upload-cloud:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-user-check:before {
  content: "";
}

.icon-user-minus:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-x:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-video:before {
  content: "";
}

.icon-video-off:before {
  content: "";
}

.icon-voicemail:before {
  content: "";
}

.icon-volume:before {
  content: "";
}

.icon-volume-1:before {
  content: "";
}

.icon-volume-2:before {
  content: "";
}

.icon-volume-x:before {
  content: "";
}

.icon-watch:before {
  content: "";
}

.icon-wifi:before {
  content: "";
}

.icon-wifi-off:before {
  content: "";
}

.icon-wind:before {
  content: "";
}

.icon-x:before {
  content: "";
}

.icon-x-circle:before {
  content: "";
}

.icon-x-octagon:before {
  content: "";
}

.icon-x-square:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-zap:before {
  content: "";
}

.icon-zap-off:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-zoom-out:before {
  content: "";
}

.post-player-shadow {
  position: absolute;
  left: -9999px;
}



.monaco-editor {
  --monaco-monospace-font: "SF Mono", Monaco, Menlo, Consolas, "Ubuntu Mono", "Liberation Mono", "DejaVu Sans Mono", "Courier New", monospace;
  font-family: -apple-system, BlinkMacSystemFont, Segoe WPC, Segoe UI, HelveticaNeue-Light, system-ui, Ubuntu, Droid Sans, sans-serif;
}

.monaco-menu .monaco-action-bar.vertical .action-item .action-menu-item:focus .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.hc-black .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label {
  stroke-width: 1.2px;
}

.monaco-hover p {
  margin: 0;
}

.monaco-aria-container {
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  margin: -1px;
  padding: 0;
  top: 0;
  overflow: hidden;
  position: absolute !important;
}

.monaco-editor.hc-black {
  -ms-high-contrast-adjust: none;
}

@media screen and (-ms-high-contrast: active) {
  .monaco-editor.vs .view-overlays .current-line, .monaco-editor.vs-dark .view-overlays .current-line {
    border-left: 0;
    border-right: 0;
    border-color: windowtext !important;
  }

  .monaco-editor.vs .cursor, .monaco-editor.vs-dark .cursor {
    background-color: windowtext !important;
  }

  .monaco-editor.vs .dnd-target, .monaco-editor.vs-dark .dnd-target {
    border-color: windowtext !important;
  }

  .monaco-editor.vs .selected-text, .monaco-editor.vs-dark .selected-text {
    background-color: highlight !important;
  }

  .monaco-editor.vs .view-line, .monaco-editor.vs-dark .view-line {
    -ms-high-contrast-adjust: none;
  }

  .monaco-editor.vs .view-line span, .monaco-editor.vs-dark .view-line span {
    color: windowtext !important;
  }

  .monaco-editor.vs .view-line span.inline-selected-text, .monaco-editor.vs-dark .view-line span.inline-selected-text {
    color: highlighttext !important;
  }

  .monaco-editor.vs .view-overlays, .monaco-editor.vs-dark .view-overlays {
    -ms-high-contrast-adjust: none;
  }

  .monaco-editor.vs .selectionHighlight, .monaco-editor.vs-dark .selectionHighlight, .monaco-editor.vs .wordHighlight, .monaco-editor.vs-dark .wordHighlight, .monaco-editor.vs .wordHighlightStrong, .monaco-editor.vs-dark .wordHighlightStrong, .monaco-editor.vs .reference-decoration, .monaco-editor.vs-dark .reference-decoration {
    box-sizing: border-box;
    border: 2px dotted highlight !important;
    background: none !important;
  }

  .monaco-editor.vs .rangeHighlight, .monaco-editor.vs-dark .rangeHighlight {
    box-sizing: border-box;
    border: 1px dotted activeborder !important;
    background: none !important;
  }

  .monaco-editor.vs .bracket-match, .monaco-editor.vs-dark .bracket-match {
    border-color: windowtext !important;
    background: none !important;
  }

  .monaco-editor.vs .findMatch, .monaco-editor.vs-dark .findMatch, .monaco-editor.vs .currentFindMatch, .monaco-editor.vs-dark .currentFindMatch {
    box-sizing: border-box;
    border: 2px dotted activeborder !important;
    background: none !important;
  }

  .monaco-editor.vs .find-widget, .monaco-editor.vs-dark .find-widget {
    border: 1px solid windowtext;
  }

  .monaco-editor.vs .monaco-list .monaco-list-row, .monaco-editor.vs-dark .monaco-list .monaco-list-row {
    -ms-high-contrast-adjust: none;
    color: windowtext !important;
  }

  .monaco-editor.vs .monaco-list .monaco-list-row.focused, .monaco-editor.vs-dark .monaco-list .monaco-list-row.focused {
    color: highlighttext !important;
    background-color: highlight !important;
  }

  .monaco-editor.vs .monaco-list .monaco-list-row:hover, .monaco-editor.vs-dark .monaco-list .monaco-list-row:hover {
    border: 1px solid highlight;
    box-sizing: border-box;
    background: none !important;
  }

  .monaco-editor.vs .monaco-scrollable-element > .scrollbar, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar {
    -ms-high-contrast-adjust: none;
    border: 1px solid windowtext;
    box-sizing: border-box;
    background: background !important;
  }

  .monaco-editor.vs .monaco-scrollable-element > .scrollbar > .slider, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar > .slider {
    background: windowtext !important;
  }

  .monaco-editor.vs .monaco-scrollable-element > .scrollbar > .slider:hover, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar > .slider:hover, .monaco-editor.vs .monaco-scrollable-element > .scrollbar > .slider.active, .monaco-editor.vs-dark .monaco-scrollable-element > .scrollbar > .slider.active {
    background: highlight !important;
  }

  .monaco-editor.vs .decorationsOverviewRuler, .monaco-editor.vs-dark .decorationsOverviewRuler {
    opacity: 0;
  }

  .monaco-editor.vs .minimap, .monaco-editor.vs-dark .minimap {
    display: none;
  }

  .monaco-editor.vs .squiggly-d-error, .monaco-editor.vs-dark .squiggly-d-error {
    border-bottom: 4px double #e47777;
    background: none !important;
  }

  .monaco-editor.vs .squiggly-c-warning, .monaco-editor.vs-dark .squiggly-c-warning, .monaco-editor.vs .squiggly-b-info, .monaco-editor.vs-dark .squiggly-b-info {
    border-bottom: 4px double #71b771;
  }

  .monaco-editor.vs .squiggly-a-hint, .monaco-editor.vs-dark .squiggly-a-hint {
    border-bottom: 4px double #6c6c6c;
  }

  .monaco-editor.vs .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:focus .action-label {
    -ms-high-contrast-adjust: none;
    color: highlighttext !important;
    background-color: highlight !important;
  }

  .monaco-editor.vs .monaco-menu .monaco-action-bar.vertical .action-menu-item:hover .action-label, .monaco-editor.vs-dark .monaco-menu .monaco-action-bar.vertical .action-menu-item:hover .action-label {
    -ms-high-contrast-adjust: none;
    border: 1px solid highlight;
    box-sizing: border-box;
    background: none !important;
  }

  .monaco-diff-editor.vs .diffOverviewRuler, .monaco-diff-editor.vs-dark .diffOverviewRuler {
    display: none;
  }

  .monaco-editor.vs .line-insert, .monaco-editor.vs-dark .line-insert, .monaco-editor.vs .line-delete, .monaco-editor.vs-dark .line-delete {
    box-sizing: border-box;
    border: 1px solid highlight !important;
    background: none !important;
  }

  .monaco-editor.vs .char-insert, .monaco-editor.vs-dark .char-insert, .monaco-editor.vs .char-delete, .monaco-editor.vs-dark .char-delete {
    background: none !important;
  }
}

.monaco-aria-container {
  position: absolute;
  left: -999em;
}

::-ms-clear {
  display: none;
}

.monaco-editor .editor-widget input {
  color: inherit;
}

.monaco-editor {
  -webkit-text-size-adjust: 100%;
  position: relative;
  overflow: visible;
}

.monaco-editor .overflow-guard {
  position: relative;
  overflow: hidden;
}

.monaco-editor .view-overlays {
  position: absolute;
  top: 0;
}

.monaco-editor .inputarea {
  min-width: 0;
  min-height: 0;
  resize: none;
  color: #0000;
  background-color: #0000;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
  outline: none !important;
}

.monaco-editor .inputarea.ime-input {
  z-index: 10;
}

.monaco-editor .margin-view-overlays .line-numbers {
  font-variant-numeric: tabular-nums;
  text-align: right;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: default;
  height: 100%;
  display: inline-block;
  position: absolute;
}

.monaco-editor .relative-current-line-number {
  text-align: left;
  width: 100%;
  display: inline-block;
}

.monaco-editor .margin-view-overlays .line-numbers.lh-odd {
  margin-top: 1px;
}

.monaco-mouse-cursor-text {
  cursor: text;
}

.vs-dark .mac .monaco-mouse-cursor-text, .hc-black .mac .monaco-mouse-cursor-text, .vs-dark.mac .monaco-mouse-cursor-text, .hc-black.mac .monaco-mouse-cursor-text {
  cursor: -webkit-image-set(url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAL0lEQVQoz2NgCD3x//9/BhBYBWdhgFVAiVW4JBFKGIa4AqD0//9D3pt4I4tAdAMAHTQ/j5Zom30AAAAASUVORK5CYII=") 1x, url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAAz0lEQVRIx2NgYGBY/R8I/vx5eelX3n82IJ9FxGf6tksvf/8FiTMQAcAGQMDvSwu09abffY8QYSAScNk45G198eX//yev73/4///701eh//kZSARckrNBRvz//+8+6ZohwCzjGNjdgQxkAg7B9WADeBjIBqtJCbhRA0YNoIkBSNmaPEMoNmA0FkYNoFKhapJ6FGyAH3nauaSmPfwI0v/3OukVi0CIZ+F25KrtYcx/CTIy0e+rC7R1Z4KMICVTQQ14feVXIbR695u14+Ir4gwAAD49E54wc1kWAAAAAElFTkSuQmCC") 2x) 5 8, text;
}

.monaco-editor .view-overlays .current-line, .monaco-editor .margin-view-overlays .current-line {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .margin-view-overlays .current-line.current-line-margin.current-line-margin-both {
  border-right: 0;
}

.monaco-editor .lines-content .cdr {
  position: absolute;
}

.monaco-scrollable-element > .scrollbar > .scra {
  cursor: pointer;
  font-size: 11px !important;
}

.monaco-scrollable-element > .visible {
  opacity: 1;
  background: none;
  transition: opacity .1s linear;
}

.monaco-scrollable-element > .invisible {
  opacity: 0;
  pointer-events: none;
}

.monaco-scrollable-element > .invisible.fade {
  transition: opacity .8s linear;
}

.monaco-scrollable-element > .shadow {
  display: none;
  position: absolute;
}

.monaco-scrollable-element > .shadow.top {
  height: 3px;
  width: 100%;
  display: block;
  top: 0;
  left: 3px;
}

.monaco-scrollable-element > .shadow.left {
  height: 100%;
  width: 3px;
  display: block;
  top: 3px;
  left: 0;
}

.monaco-scrollable-element > .shadow.top-left-corner {
  height: 3px;
  width: 3px;
  display: block;
  top: 0;
  left: 0;
}

.monaco-editor .glyph-margin {
  position: absolute;
  top: 0;
}

.monaco-editor .margin-view-overlays .cgmr {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.monaco-editor .lines-content .core-guide {
  box-sizing: border-box;
  position: absolute;
}

.mtkcontrol {
  color: #fff !important;
  background: #960000 !important;
}

.monaco-editor.no-user-select .lines-content, .monaco-editor.no-user-select .view-line, .monaco-editor.no-user-select .view-lines {
  user-select: none;
}

.monaco-editor .view-lines {
  white-space: nowrap;
}

.monaco-editor .view-line {
  width: 100%;
  position: absolute;
}

.monaco-editor .mtkz {
  display: inline-block;
}

.monaco-editor .lines-decorations {
  background: #fff;
  position: absolute;
  top: 0;
}

.monaco-editor .margin-view-overlays .cldr {
  height: 100%;
  position: absolute;
}

.monaco-editor .margin-view-overlays .cmdr {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
}

.monaco-editor .minimap.slider-mouseover .minimap-slider {
  opacity: 0;
  transition: opacity .1s linear;
}

.monaco-editor .minimap.slider-mouseover:hover .minimap-slider, .monaco-editor .minimap.slider-mouseover .minimap-slider.active {
  opacity: 1;
}

.monaco-editor .minimap-shadow-hidden {
  width: 0;
  position: absolute;
}

.monaco-editor .minimap-shadow-visible {
  width: 6px;
  position: absolute;
  left: -6px;
}

.monaco-editor.no-minimap-shadow .minimap-shadow-visible {
  width: 1px;
  position: absolute;
  left: -1px;
}

.monaco-editor .overlayWidgets {
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .view-ruler {
  position: absolute;
  top: 0;
}

.monaco-editor .scroll-decoration {
  height: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-editor .lines-content .cslr {
  position: absolute;
}

.monaco-editor .top-left-radius {
  border-top-left-radius: 3px;
}

.monaco-editor .bottom-left-radius {
  border-bottom-left-radius: 3px;
}

.monaco-editor .top-right-radius {
  border-top-right-radius: 3px;
}

.monaco-editor .bottom-right-radius {
  border-bottom-right-radius: 3px;
}

.monaco-editor.hc-black .top-left-radius {
  border-top-left-radius: 0;
}

.monaco-editor.hc-black .bottom-left-radius {
  border-bottom-left-radius: 0;
}

.monaco-editor.hc-black .top-right-radius {
  border-top-right-radius: 0;
}

.monaco-editor.hc-black .bottom-right-radius {
  border-bottom-right-radius: 0;
}

.monaco-editor .cursors-layer {
  position: absolute;
  top: 0;
}

.monaco-editor .cursors-layer > .cursor {
  position: absolute;
  overflow: hidden;
}

.monaco-editor .cursors-layer.cursor-smooth-caret-animation > .cursor {
  transition: all 80ms;
}

.monaco-editor .cursors-layer.cursor-block-outline-style > .cursor {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  background: none !important;
}

.monaco-editor .cursors-layer.cursor-underline-style > .cursor {
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  background: none !important;
}

.monaco-editor .cursors-layer.cursor-underline-thin-style > .cursor {
  box-sizing: border-box;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background: none !important;
}

@keyframes monaco-cursor-smooth {
  0%, 20% {
    opacity: 1;
  }

  60%, 100% {
    opacity: 0;
  }
}

@keyframes monaco-cursor-phase {
  0%, 20% {
    opacity: 1;
  }

  90%, 100% {
    opacity: 0;
  }
}

@keyframes monaco-cursor-expand {
  0%, 20% {
    transform: scaleY(1);
  }

  80%, 100% {
    transform: scaleY(0);
  }
}

.cursor-smooth {
  animation: .5s ease-in-out 20 alternate monaco-cursor-smooth;
}

.cursor-phase {
  animation: .5s ease-in-out 20 alternate monaco-cursor-phase;
}

.cursor-expand > .cursor {
  animation: .5s ease-in-out 20 alternate monaco-cursor-expand;
}

.monaco-diff-editor .diffOverview {
  z-index: 9;
}

.monaco-diff-editor .diffOverview .diffViewport {
  z-index: 10;
}

.monaco-diff-editor.vs .diffOverview {
  background: #00000008;
}

.monaco-diff-editor.vs-dark .diffOverview {
  background: #ffffff03;
}

.monaco-scrollable-element.modified-in-monaco-diff-editor.vs .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.vs-dark .scrollbar, .monaco-scrollable-element.modified-in-monaco-diff-editor.hc-black .scrollbar {
  background: none;
}

.monaco-scrollable-element.modified-in-monaco-diff-editor .slider {
  z-index: 10;
}

.modified-in-monaco-diff-editor .slider.active {
  background: #ababab66;
}

.modified-in-monaco-diff-editor.hc-black .slider.active {
  background: none;
}

.monaco-editor .insert-sign, .monaco-diff-editor .insert-sign, .monaco-editor .delete-sign, .monaco-diff-editor .delete-sign {
  align-items: center;
  opacity: .7 !important;
  font-size: 11px !important;
  display: flex !important;
}

.monaco-editor.hc-black .insert-sign, .monaco-diff-editor.hc-black .insert-sign, .monaco-editor.hc-black .delete-sign, .monaco-diff-editor.hc-black .delete-sign {
  opacity: 1;
}

.monaco-editor .inline-deleted-margin-view-zone, .monaco-editor .inline-added-margin-view-zone {
  text-align: right;
}

.monaco-editor .view-zones .view-lines .view-line span {
  display: inline-block;
}

.monaco-editor .margin-view-zones .lightbulb-glyph:hover {
  cursor: pointer;
}

:root {
  --sash-size: 4px;
}

.monaco-sash {
  z-index: 35;
  touch-action: none;
  position: absolute;
}

.monaco-sash.disabled {
  pointer-events: none;
}

.monaco-sash.mac.vertical {
  cursor: col-resize;
}

.monaco-sash.vertical.minimum {
  cursor: e-resize;
}

.monaco-sash.vertical.maximum {
  cursor: w-resize;
}

.monaco-sash.mac.horizontal {
  cursor: row-resize;
}

.monaco-sash.horizontal.minimum {
  cursor: s-resize;
}

.monaco-sash.horizontal.maximum {
  cursor: n-resize;
}

.monaco-sash.disabled {
  cursor: default !important;
  pointer-events: none !important;
}

.monaco-sash.vertical {
  cursor: ew-resize;
  width: var(--sash-size);
  height: 100%;
  top: 0;
}

.monaco-sash.horizontal {
  cursor: ns-resize;
  width: 100%;
  height: var(--sash-size);
  left: 0;
}

.monaco-sash:not(.disabled) > .orthogonal-drag-handle {
  content: " ";
  height: calc(var(--sash-size) * 2);
  width: calc(var(--sash-size) * 2);
  z-index: 100;
  cursor: all-scroll;
  display: block;
  position: absolute;
}

.monaco-sash.horizontal.orthogonal-edge-north:not(.disabled) > .orthogonal-drag-handle.start, .monaco-sash.horizontal.orthogonal-edge-south:not(.disabled) > .orthogonal-drag-handle.end {
  cursor: nwse-resize;
}

.monaco-sash.horizontal.orthogonal-edge-north:not(.disabled) > .orthogonal-drag-handle.end, .monaco-sash.horizontal.orthogonal-edge-south:not(.disabled) > .orthogonal-drag-handle.start {
  cursor: nesw-resize;
}

.monaco-sash.vertical > .orthogonal-drag-handle.start {
  left: calc(var(--sash-size) * -.5);
  top: calc(var(--sash-size) * -1);
}

.monaco-sash.vertical > .orthogonal-drag-handle.end {
  left: calc(var(--sash-size) * -.5);
  bottom: calc(var(--sash-size) * -1);
}

.monaco-sash.horizontal > .orthogonal-drag-handle.start {
  top: calc(var(--sash-size) * -.5);
  left: calc(var(--sash-size) * -1);
}

.monaco-sash.horizontal > .orthogonal-drag-handle.end {
  top: calc(var(--sash-size) * -.5);
  right: calc(var(--sash-size) * -1);
}

.monaco-sash:before {
  content: "";
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: none;
  transition: background-color .1s ease-out;
  position: absolute;
}

.monaco-sash.vertical:before {
  width: var(--sash-hover-size);
  left: calc(50% - (var(--sash-hover-size) / 2) );
}

.monaco-sash.horizontal:before {
  height: var(--sash-hover-size);
  top: calc(50% - (var(--sash-hover-size) / 2) );
}

.monaco-sash.debug {
  background: #0ff;
}

.monaco-sash.debug.disabled {
  background: #0ff3;
}

.monaco-sash.debug:not(.disabled) > .orthogonal-drag-handle {
  background: red;
}

.monaco-diff-editor .diff-review-line-number {
  text-align: right;
  display: inline-block;
}

.monaco-diff-editor .diff-review {
  user-select: none;
  position: absolute;
}

.monaco-diff-editor .diff-review-summary {
  padding-left: 10px;
}

.monaco-diff-editor .diff-review-shadow {
  position: absolute;
}

.monaco-diff-editor .diff-review-row {
  white-space: pre;
}

.monaco-diff-editor .diff-review-table {
  min-width: 100%;
  display: table;
}

.monaco-diff-editor .diff-review-row {
  width: 100%;
  display: table-row;
}

.monaco-diff-editor .diff-review-spacer {
  width: 10px;
  vertical-align: middle;
  display: inline-block;
}

.monaco-diff-editor .diff-review-spacer > .codicon {
  font-size: 9px !important;
}

.monaco-diff-editor .diff-review-actions {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 10px;
}

.monaco-diff-editor .diff-review-actions .action-label {
  width: 16px;
  height: 16px;
  margin: 2px 0;
}

.monaco-action-bar {
  white-space: nowrap;
  height: 100%;
}

.monaco-action-bar .actions-container {
  height: 100%;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.monaco-action-bar.vertical .actions-container {
  display: inline-block;
}

.monaco-action-bar .action-item {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;
}

.monaco-action-bar .action-item.disabled {
  cursor: default;
}

.monaco-action-bar .action-item .icon, .monaco-action-bar .action-item .codicon {
  display: block;
}

.monaco-action-bar .action-item .codicon {
  width: 16px;
  height: 16px;
  align-items: center;
  display: flex;
}

.monaco-action-bar .action-label {
  border-radius: 5px;
  padding: 3px;
  font-size: 11px;
}

.monaco-action-bar .action-item.disabled .action-label, .monaco-action-bar .action-item.disabled .action-label:before, .monaco-action-bar .action-item.disabled .action-label:hover {
  opacity: .4;
}

.monaco-action-bar.vertical {
  text-align: left;
}

.monaco-action-bar.vertical .action-item {
  display: block;
}

.monaco-action-bar.vertical .action-label.separator {
  border-bottom: 1px solid #bbb;
  margin-left: .8em;
  margin-right: .8em;
  padding-top: 1px;
  display: block;
}

.monaco-action-bar .action-item .action-label.separator {
  width: 1px;
  height: 16px;
  cursor: default;
  min-width: 1px;
  background-color: #bbb;
  padding: 0;
  margin: 5px 4px !important;
}

.secondary-actions .monaco-action-bar .action-label {
  margin-left: 6px;
}

.monaco-action-bar .action-item.select-container {
  max-width: 170px;
  min-width: 60px;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  overflow: hidden;
}

.monaco-action-bar .action-item.action-dropdown-item {
  display: flex;
}

.monaco-action-bar .action-item.action-dropdown-item > .action-label {
  margin-right: 1px;
}

@font-face {
  font-family: codicon;
  font-display: block;
  src: url("codicon.7d93afb2.ttf") format("truetype");
}

.codicon[class*="codicon-"] {
  text-rendering: auto;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  font: 16px / 1 codicon;
  text-decoration: none;
  display: inline-block;
}

.codicon-wrench-subaction {
  opacity: .5;
}

@keyframes codicon-spin {
  100% {
    transform: rotate(360deg);
  }
}

.codicon-sync.codicon-modifier-spin, .codicon-loading.codicon-modifier-spin, .codicon-gear.codicon-modifier-spin, .codicon-notebook-state-executing.codicon-modifier-spin {
  animation: 1.5s steps(30, end) infinite codicon-spin;
}

.codicon-modifier-disabled {
  opacity: .4;
}

.codicon-loading, .codicon-tree-item-loading:before {
  animation-duration: 1s !important;
  animation-timing-function: cubic-bezier(.53, .21, .29, .67) !important;
}

.context-view {
  z-index: 2500;
  position: absolute;
}

.context-view.fixed {
  all: initial;
  z-index: 2500;
  color: inherit;
  font-family: inherit;
  font-size: 13px;
  position: fixed;
}

.context-view .monaco-menu {
  min-width: 130px;
}

.monaco-list {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  position: relative;
}

.monaco-list.mouse-support {
  user-select: none;
}

.monaco-list > .monaco-scrollable-element {
  height: 100%;
}

.monaco-list-rows {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-list.horizontal-scrolling .monaco-list-rows {
  width: auto;
  min-width: 100%;
}

.monaco-list-row {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.monaco-list.mouse-support .monaco-list-row {
  cursor: pointer;
  touch-action: none;
}

.monaco-list-row.scrolling {
  display: none !important;
}

.monaco-list.element-focused, .monaco-list.selection-single, .monaco-list.selection-multiple {
  outline: 0 !important;
}

.monaco-drag-image {
  z-index: 1000;
  border-radius: 10px;
  padding: 1px 7px;
  font-size: 12px;
  display: inline-block;
  position: absolute;
}

.monaco-list-type-filter {
  max-width: calc(100% - 10px);
  text-overflow: ellipsis;
  text-align: right;
  box-sizing: border-box;
  cursor: all-scroll;
  height: 20px;
  z-index: 1;
  border-radius: 2px;
  align-items: center;
  padding: 0 3px;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  position: absolute;
  top: 4px;
  overflow: hidden;
}

.monaco-list-type-filter.dragging {
  transition: top .2s, left .2s;
}

.monaco-list-type-filter.ne {
  right: 4px;
}

.monaco-list-type-filter.nw {
  left: 4px;
}

.monaco-list-type-filter > .controls {
  box-sizing: border-box;
  width: 0;
  align-items: center;
  transition: width .2s;
  display: flex;
}

.monaco-list-type-filter.dragging > .controls, .monaco-list-type-filter:hover > .controls {
  width: 36px;
}

.monaco-list-type-filter > .controls > * {
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: none;
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.monaco-list-type-filter > .controls > .filter {
  margin-left: 4px;
}

.monaco-list-type-filter-message {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: normal;
  opacity: .7;
  pointer-events: none;
  padding: 40px 1em 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-list-type-filter-message:empty {
  display: none;
}

.monaco-list-type-filter {
  cursor: grab;
}

.monaco-list-type-filter.dragging {
  cursor: grabbing;
}

.monaco-split-view2 {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-split-view2 > .sash-container {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
}

.monaco-split-view2 > .sash-container > .monaco-sash {
  pointer-events: initial;
}

.monaco-split-view2 > .monaco-scrollable-element {
  width: 100%;
  height: 100%;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container > .split-view-view {
  white-space: initial;
  position: absolute;
}

.monaco-split-view2 > .monaco-scrollable-element > .split-view-container > .split-view-view:not(.visible) {
  display: none;
}

.monaco-split-view2.vertical > .monaco-scrollable-element > .split-view-container > .split-view-view {
  width: 100%;
}

.monaco-split-view2.horizontal > .monaco-scrollable-element > .split-view-container > .split-view-view {
  height: 100%;
}

.monaco-split-view2.separator-border > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  content: " ";
  z-index: 5;
  pointer-events: none;
  background-color: var(--separator-border);
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-split-view2.separator-border.horizontal > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  height: 100%;
  width: 1px;
}

.monaco-split-view2.separator-border.vertical > .monaco-scrollable-element > .split-view-container > .split-view-view:not(:first-child):before {
  height: 1px;
  width: 100%;
}

.monaco-table {
  height: 100%;
  width: 100%;
  white-space: nowrap;
  flex-direction: column;
  display: flex;
  position: relative;
}

.monaco-table > .monaco-split-view2 {
  border-bottom: 1px solid #0000;
}

.monaco-table > .monaco-list {
  flex: 1;
}

.monaco-table-tr {
  height: 100%;
  display: flex;
}

.monaco-table-th {
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  font-weight: bold;
  overflow: hidden;
}

.monaco-table-th, .monaco-table-td {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
  overflow: hidden;
}

.monaco-table > .monaco-split-view2 .monaco-sash.vertical:before {
  content: "";
  left: calc(var(--sash-size) / 2);
  width: 0;
  border-left: 1px solid #0000;
  position: absolute;
}

.monaco-table > .monaco-split-view2, .monaco-table > .monaco-split-view2 .monaco-sash.vertical:before {
  transition: border-color .2s ease-out;
}

.monaco-tl-row {
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

.monaco-tl-indent {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 16px;
}

.hide-arrows .monaco-tl-indent {
  left: 12px;
}

.monaco-tl-indent > .indent-guide {
  box-sizing: border-box;
  height: 100%;
  border-left: 1px solid #0000;
  transition: border-color .1s linear;
  display: inline-block;
}

.monaco-tl-twistie, .monaco-tl-contents {
  height: 100%;
}

.monaco-tl-twistie {
  text-align: right;
  width: 16px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  font-size: 10px;
  transform: translateX(3px);
  display: flex !important;
}

.monaco-tl-contents {
  flex: 1;
  overflow: hidden;
}

.monaco-tl-twistie:before {
  border-radius: 20px;
}

.monaco-tl-twistie.collapsed:before {
  transform: rotate(-90deg);
}

.monaco-tl-twistie.codicon-tree-item-loading:before {
  animation: 1.25s steps(30, end) infinite codicon-spin;
}

.quick-input-widget {
  font-size: 13px;
}

.quick-input-widget .monaco-highlighted-label .highlight, .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0066bf;
}

.vs .quick-input-widget .monaco-list-row.focused .monaco-highlighted-label .highlight, .vs .quick-input-widget .monaco-list-row.focused .monaco-highlighted-label .highlight {
  color: #9dddff;
}

.vs-dark .quick-input-widget .monaco-highlighted-label .highlight, .vs-dark .quick-input-widget .monaco-highlighted-label .highlight {
  color: #0097fb;
}

.hc-black .quick-input-widget .monaco-highlighted-label .highlight, .hc-black .quick-input-widget .monaco-highlighted-label .highlight {
  color: #f38518;
}

.monaco-keybinding > .monaco-keybinding-key {
  color: #555;
  background-color: #ddd6;
  border: 1px solid #ccc6;
  border-bottom-color: #bbb6;
  box-shadow: inset 0 -1px #bbb6;
}

.hc-black .monaco-keybinding > .monaco-keybinding-key {
  box-shadow: none;
  color: #fff;
  background-color: #0000;
  border: 1px solid #6fc3df;
}

.vs-dark .monaco-keybinding > .monaco-keybinding-key {
  color: #ccc;
  background-color: #8080802b;
  border: 1px solid #3339;
  border-bottom-color: #4449;
  box-shadow: inset 0 -1px #4449;
}

.monaco-text-button {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

.monaco-text-button:focus {
  outline-offset: 2px !important;
}

.monaco-text-button:hover {
  text-decoration: none !important;
}

.monaco-button.disabled:focus, .monaco-button.disabled {
  cursor: default;
  opacity: .4 !important;
}

.monaco-text-button > .codicon {
  margin: 0 .2em;
  color: inherit !important;
}

.monaco-button-dropdown {
  cursor: pointer;
  display: flex;
}

.monaco-button-dropdown > .monaco-dropdown-button {
  margin-left: 1px;
}

.monaco-description-button {
  flex-direction: column;
}

.monaco-description-button .monaco-button-label {
  font-weight: 500;
}

.monaco-description-button .monaco-button-description {
  font-style: italic;
}

.monaco-count-badge {
  min-width: 18px;
  min-height: 18px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: normal;
  line-height: 11px;
  display: inline-block;
}

.monaco-count-badge.long {
  min-height: auto;
  border-radius: 2px;
  padding: 2px 3px;
  line-height: normal;
}

.monaco-progress-container {
  width: 100%;
  height: 5px;
  overflow: hidden;
}

.monaco-progress-container .progress-bit {
  width: 2%;
  height: 5px;
  display: none;
  position: absolute;
  left: 0;
}

.monaco-progress-container.active .progress-bit {
  display: inherit;
}

.monaco-progress-container.discrete .progress-bit {
  transition: width .1s linear;
  left: 0;
}

.monaco-progress-container.discrete.done .progress-bit {
  width: 100%;
}

.monaco-progress-container.infinite .progress-bit {
  animation-name: progress;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0);
}

@keyframes progress {
  from {
    transform: translateX(0%)scaleX(1);
  }

  50% {
    transform: translateX(2500%)scaleX(3);
  }

  to {
    transform: translateX(4900%)scaleX(1);
  }
}

.quick-input-widget {
  width: 600px;
  z-index: 2000;
  margin-left: -300px;
  padding: 0 1px 1px;
  position: absolute;
  left: 50%;
}

.quick-input-titlebar {
  align-items: center;
  display: flex;
}

.quick-input-left-action-bar {
  flex: 1;
  margin-left: 4px;
  display: flex;
}

.quick-input-title {
  text-align: center;
  text-overflow: ellipsis;
  padding: 3px 0;
  overflow: hidden;
}

.quick-input-right-action-bar {
  flex: 1;
  margin-right: 4px;
  display: flex;
}

.quick-input-right-action-bar > .actions-container {
  justify-content: flex-end;
}

.quick-input-titlebar .monaco-action-bar .action-label.codicon {
  background-position: center;
  background-repeat: no-repeat;
  padding: 2px;
}

.quick-input-description {
  margin: 6px;
}

.quick-input-header .quick-input-description {
  margin: 4px 2px;
}

.quick-input-header {
  margin-bottom: -2px;
  padding: 6px 6px 0;
  display: flex;
}

.quick-input-widget.hidden-input .quick-input-header {
  margin-bottom: 0;
  padding: 0;
}

.quick-input-and-message {
  min-width: 0;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.quick-input-check-all {
  align-self: center;
  margin: 0;
}

.quick-input-filter {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.quick-input-box {
  flex-grow: 1;
}

.quick-input-widget.show-checkboxes .quick-input-box, .quick-input-widget.show-checkboxes .quick-input-message {
  margin-left: 5px;
}

.quick-input-visible-count {
  position: absolute;
  left: -10000px;
}

.quick-input-count {
  align-self: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 4px;
}

.quick-input-count .monaco-count-badge {
  vertical-align: middle;
  min-height: auto;
  border-radius: 2px;
  padding: 2px 4px;
  line-height: normal;
}

.quick-input-action {
  margin-left: 6px;
}

.quick-input-action .monaco-text-button {
  height: 27.5px;
  align-items: center;
  padding: 0 6px;
  font-size: 11px;
  display: flex;
}

.quick-input-message {
  overflow-wrap: break-word;
  margin-top: -1px;
  padding: 5px 5px 2px;
}

.quick-input-message > .codicon {
  vertical-align: text-bottom;
  margin: 0 .2em;
}

.quick-input-progress.monaco-progress-container {
  position: relative;
}

.quick-input-progress.monaco-progress-container, .quick-input-progress.monaco-progress-container .progress-bit {
  height: 2px;
}

.quick-input-list {
  margin-top: 6px;
  line-height: 22px;
}

.quick-input-widget.hidden-input .quick-input-list {
  margin-top: 0;
}

.quick-input-list .monaco-list {
  max-height: 440px;
  overflow: hidden;
}

.quick-input-list .quick-input-list-entry {
  box-sizing: border-box;
  height: 100%;
  padding: 0 6px;
  display: flex;
  overflow: hidden;
}

.quick-input-list .quick-input-list-entry.quick-input-list-separator-border {
  border-top-style: solid;
  border-top-width: 1px;
}

.quick-input-list .monaco-list-row[data-index="0"] .quick-input-list-entry.quick-input-list-separator-border {
  border-top-style: none;
}

.quick-input-list .quick-input-list-label {
  height: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.quick-input-list .quick-input-list-checkbox {
  align-self: center;
  margin: 0;
}

.quick-input-list .quick-input-list-rows {
  text-overflow: ellipsis;
  height: 100%;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
  display: flex;
  overflow: hidden;
}

.quick-input-widget.show-checkboxes .quick-input-list .quick-input-list-rows {
  margin-left: 10px;
}

.quick-input-widget .quick-input-list .quick-input-list-checkbox {
  display: none;
}

.quick-input-widget.show-checkboxes .quick-input-list .quick-input-list-checkbox {
  display: inline;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row {
  align-items: center;
  display: flex;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row .monaco-icon-label, .quick-input-list .quick-input-list-rows > .quick-input-list-row .monaco-icon-label .monaco-icon-label-container > .monaco-icon-name-container {
  flex: 1;
}

.quick-input-list .quick-input-list-rows > .quick-input-list-row .codicon[class*="codicon-"] {
  vertical-align: text-bottom;
}

.quick-input-list .quick-input-list-rows .monaco-highlighted-label span {
  opacity: 1;
}

.quick-input-list .quick-input-list-entry .quick-input-list-entry-keybinding {
  margin-right: 8px;
}

.quick-input-list .quick-input-list-label-meta {
  opacity: .7;
  text-overflow: ellipsis;
  line-height: normal;
  overflow: hidden;
}

.quick-input-list .monaco-highlighted-label .highlight {
  font-weight: bold;
}

.quick-input-list .quick-input-list-entry .quick-input-list-separator {
  margin-right: 8px;
}

.quick-input-list .quick-input-list-entry-action-bar {
  flex: 0;
  display: flex;
  overflow: visible;
}

.quick-input-list .quick-input-list-entry-action-bar .action-label {
  display: none;
}

.quick-input-list .quick-input-list-entry-action-bar .action-label.codicon {
  margin-right: 4px;
  padding: 0 2px 2px;
}

.quick-input-list .quick-input-list-entry-action-bar {
  margin-top: 1px;
  margin-right: 4px;
}

.quick-input-list .quick-input-list-entry .quick-input-list-entry-action-bar .action-label.always-visible, .quick-input-list .quick-input-list-entry:hover .quick-input-list-entry-action-bar .action-label, .quick-input-list .monaco-list-row.focused .quick-input-list-entry-action-bar .action-label {
  display: flex;
}

.quick-input-list .monaco-list-row.focused .monaco-keybinding-key, .quick-input-list .monaco-list-row.focused .quick-input-list-entry .quick-input-list-separator {
  color: inherit;
}

.quick-input-list .monaco-list-row.focused .monaco-keybinding-key {
  background: none;
}

.monaco-inputbox {
  box-sizing: border-box;
  font-size: inherit;
  padding: 0;
  display: block;
  position: relative;
}

.monaco-inputbox.idle {
  border: 1px solid #0000;
}

.monaco-inputbox > .ibwrapper > .input, .monaco-inputbox > .ibwrapper > .mirror {
  padding: 4px;
}

.monaco-inputbox > .ibwrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.monaco-inputbox > .ibwrapper > .input {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  resize: none;
  color: inherit;
  border: none;
  display: inline-block;
}

.monaco-inputbox > .ibwrapper > input {
  text-overflow: ellipsis;
}

.monaco-inputbox > .ibwrapper > textarea.input {
  -ms-overflow-style: none;
  scrollbar-width: none;
  outline: none;
  display: block;
}

.monaco-inputbox > .ibwrapper > textarea.input::-webkit-scrollbar {
  display: none;
}

.monaco-inputbox > .ibwrapper > textarea.input.empty {
  white-space: nowrap;
}

.monaco-inputbox > .ibwrapper > .mirror {
  width: 100%;
  box-sizing: border-box;
  white-space: pre-wrap;
  visibility: hidden;
  word-wrap: break-word;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.monaco-inputbox-container {
  text-align: right;
}

.monaco-inputbox-container .monaco-inputbox-message {
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  word-wrap: break-word;
  margin-top: -1px;
  padding: .4em;
  font-size: 12px;
  line-height: 17px;
  display: inline-block;
  overflow: hidden;
}

.monaco-inputbox .monaco-action-bar {
  position: absolute;
  top: 4px;
  right: 2px;
}

.monaco-inputbox .monaco-action-bar .action-item {
  margin-left: 2px;
}

.monaco-inputbox .monaco-action-bar .action-item .codicon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
}

.monaco-icon-label {
  text-overflow: ellipsis;
  display: flex;
  overflow: hidden;
}

.monaco-icon-label:before {
  width: 16px;
  height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 16px;
  flex-shrink: 0;
  padding-right: 6px;
  display: inline-block;
  line-height: inherit !important;
}

.monaco-icon-label > .monaco-icon-label-container {
  min-width: 0;
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-name-container > .label-name {
  color: inherit;
  white-space: pre;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-name-container > .label-name > .label-separator {
  opacity: .5;
  margin: 0 2px;
}

.monaco-icon-label > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  opacity: .7;
  white-space: pre;
  margin-left: .5em;
  font-size: .9em;
}

.monaco-icon-label.nowrap > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  white-space: nowrap;
}

.vs .monaco-icon-label > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  opacity: .95;
}

.monaco-icon-label.italic > .monaco-icon-label-container > .monaco-icon-name-container > .label-name, .monaco-icon-label.italic > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  font-style: italic;
}

.monaco-icon-label.deprecated {
  opacity: .66;
  text-decoration: line-through;
}

.monaco-icon-label.italic:after {
  font-style: italic;
}

.monaco-icon-label.strikethrough > .monaco-icon-label-container > .monaco-icon-name-container > .label-name, .monaco-icon-label.strikethrough > .monaco-icon-label-container > .monaco-icon-description-container > .label-description {
  text-decoration: line-through;
}

.monaco-icon-label:after {
  opacity: .75;
  text-align: center;
  margin: auto 16px 0 5px;
  font-size: 90%;
  font-weight: 600;
}

.monaco-list:focus .selected .monaco-icon-label, .monaco-list:focus .selected .monaco-icon-label:after {
  color: inherit !important;
}

.monaco-list-row.focused.selected .label-description, .monaco-list-row.selected .label-description {
  opacity: .8;
}

.monaco-keybinding {
  align-items: center;
  line-height: 10px;
  display: flex;
}

.monaco-keybinding > .monaco-keybinding-key {
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  margin: 0 2px;
  padding: 3px 5px;
  font-size: 11px;
  display: inline-block;
}

.monaco-keybinding > .monaco-keybinding-key:first-child {
  margin-left: 0;
}

.monaco-keybinding > .monaco-keybinding-key:last-child {
  margin-right: 0;
}

.monaco-keybinding > .monaco-keybinding-key-separator {
  display: inline-block;
}

.monaco-keybinding > .monaco-keybinding-key-chord-separator {
  width: 6px;
}

.monaco-editor .selection-anchor {
  background-color: #007acc;
  width: 2px !important;
}

.monaco-editor .bracket-match {
  box-sizing: border-box;
}

.monaco-editor .monaco-editor-overlaymessage {
  z-index: 10000;
  padding-bottom: 8px;
}

.monaco-editor .monaco-editor-overlaymessage.below {
  z-index: 10000;
  padding-top: 8px;
  padding-bottom: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.monaco-editor .monaco-editor-overlaymessage.fadeIn {
  animation: .15s ease-out fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.monaco-editor .monaco-editor-overlaymessage.fadeOut {
  animation: .1s ease-out fadeOut;
}

.monaco-editor .monaco-editor-overlaymessage .message {
  color: var(--vscode-inputValidation-infoForeground);
  background-color: var(--vscode-inputValidation-infoBackground);
  border: 1px solid var(--vscode-inputValidation-infoBorder);
  padding: 1px 4px;
}

.monaco-editor.hc-black .monaco-editor-overlaymessage .message {
  border-width: 2px;
}

.monaco-editor .monaco-editor-overlaymessage .anchor {
  z-index: 1000;
  border: 8px solid #0000;
  position: absolute;
  width: 0 !important;
  height: 0 !important;
}

.monaco-editor .monaco-editor-overlaymessage .anchor.top {
  border-bottom-color: var(--vscode-inputValidation-infoBorder);
}

.monaco-editor .monaco-editor-overlaymessage .anchor.below {
  border-top-color: var(--vscode-inputValidation-infoBorder);
}

.monaco-editor .monaco-editor-overlaymessage:not(.below) .anchor.top, .monaco-editor .monaco-editor-overlaymessage.below .anchor.below {
  display: none;
}

.monaco-editor .monaco-editor-overlaymessage.below .anchor.top {
  display: inherit;
  top: -8px;
}

.monaco-editor .contentWidgets .codicon-light-bulb, .monaco-editor .contentWidgets .codicon-lightbulb-autofix {
  justify-content: center;
  align-items: center;
  display: flex;
}

.monaco-editor .contentWidgets .codicon-light-bulb:hover, .monaco-editor .contentWidgets .codicon-lightbulb-autofix:hover {
  cursor: pointer;
}

.monaco-editor .codelens-decoration {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--vscode-editorCodeLens-foreground);
  display: inline-block;
  overflow: hidden;
}

.monaco-editor .codelens-decoration > span, .monaco-editor .codelens-decoration > a {
  user-select: none;
  white-space: nowrap;
  vertical-align: sub;
}

.monaco-editor .codelens-decoration > a {
  text-decoration: none;
}

.monaco-editor .codelens-decoration > a:hover {
  cursor: pointer;
  color: var(--vscoce-editorLink-activeForeground) !important;
}

.monaco-editor .codelens-decoration > a:hover .codicon {
  color: var(--vscoce-editorLink-activeForeground) !important;
}

.monaco-editor .codelens-decoration .codicon {
  vertical-align: middle;
  color: var(--vscode-editorCodeLens-foreground);
  color: currentColor !important;
}

.monaco-editor .codelens-decoration > a:hover .codicon:before {
  cursor: pointer;
}

@keyframes fadein {
  0% {
    opacity: 0;
    visibility: visible;
  }

  100% {
    opacity: 1;
  }
}

.monaco-editor .codelens-decoration.fadein {
  animation: .1s linear fadein;
}

.monaco-editor .goto-definition-link {
  cursor: pointer;
  text-decoration: underline;
}

.monaco-editor .peekview-widget .head {
  box-sizing: border-box;
  display: flex;
}

.monaco-editor .peekview-widget .head .peekview-title {
  min-width: 0;
  align-items: center;
  margin-left: 20px;
  font-size: 13px;
  display: flex;
}

.monaco-editor .peekview-widget .head .peekview-title.clickable {
  cursor: pointer;
}

.monaco-editor .peekview-widget .head .peekview-title .dirname:not(:empty) {
  margin-left: .5em;
  font-size: .9em;
}

.monaco-editor .peekview-widget .head .peekview-title .meta, .monaco-editor .peekview-widget .head .peekview-title .dirname {
  white-space: nowrap;
}

.monaco-editor .peekview-widget .head .peekview-title .filename {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.monaco-editor .peekview-widget .head .peekview-title .meta:not(:empty):before {
  content: "-";
  padding: 0 .3em;
}

.monaco-editor .peekview-widget .head .peekview-actions {
  text-align: right;
  flex: 1;
  padding-right: 2px;
}

.monaco-editor .peekview-widget .head .peekview-actions > .monaco-action-bar {
  display: inline-block;
}

.monaco-editor .peekview-widget .head .peekview-actions > .monaco-action-bar, .monaco-editor .peekview-widget .head .peekview-actions > .monaco-action-bar > .actions-container {
  height: 100%;
}

.monaco-editor .peekview-widget > .body {
  border-top: 1px solid;
  position: relative;
}

.monaco-editor .peekview-widget .head .peekview-title .codicon {
  margin-right: 4px;
}

.monaco-editor .peekview-widget .monaco-list .monaco-list-row.focused .codicon {
  color: inherit !important;
}

.monaco-editor .zone-widget {
  z-index: 10;
  position: absolute;
}

.monaco-editor .zone-widget .zone-widget-container {
  border-top-style: solid;
  border-top-width: 0;
  border-bottom-style: solid;
  border-bottom-width: 0;
  position: relative;
}

.monaco-dropdown {
  height: 100%;
  padding: 0;
}

.monaco-dropdown > .dropdown-label {
  cursor: pointer;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.monaco-dropdown > .dropdown-label > .action-label.disabled {
  cursor: default;
}

.monaco-dropdown-with-primary {
  border-radius: 5px;
  flex-direction: row;
  display: flex !important;
}

.monaco-dropdown-with-primary > .action-container > .action-label {
  margin-right: 0;
}

.monaco-dropdown-with-primary > .dropdown-action-container > .monaco-dropdown > .dropdown-label .codicon[class*="codicon-"] {
  margin-left: -3px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.monaco-dropdown-with-primary > .dropdown-action-container > .monaco-dropdown > .dropdown-label > .action-label {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  display: block;
}

.monaco-action-bar .action-item.menu-entry .action-label.icon {
  width: 16px;
  height: 16px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.monaco-action-bar .action-item.menu-entry .action-label {
  background-image: var(--menu-entry-icon-light);
}

.vs-dark .monaco-action-bar .action-item.menu-entry .action-label, .hc-black .monaco-action-bar .action-item.menu-entry .action-label {
  background-image: var(--menu-entry-icon-dark);
}

.monaco-dropdown-with-default {
  border-radius: 5px;
  flex-direction: row;
  display: flex !important;
}

.monaco-dropdown-with-default > .action-container > .action-label {
  margin-right: 0;
}

.monaco-dropdown-with-default > .action-container.menu-entry > .action-label.icon {
  width: 16px;
  height: 16px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.monaco-dropdown-with-default > .action-container.menu-entry > .action-label {
  background-image: var(--menu-entry-icon-light);
}

.vs-dark .monaco-dropdown-with-default > .action-container.menu-entry > .action-label, .hc-black .monaco-dropdown-with-default > .action-container.menu-entry > .action-label {
  background-image: var(--menu-entry-icon-dark);
}

.monaco-dropdown-with-default > .dropdown-action-container > .monaco-dropdown > .dropdown-label .codicon[class*="codicon-"] {
  margin-left: -3px;
  padding-left: 0;
  padding-right: 0;
  font-size: 12px;
  line-height: 16px;
}

.monaco-dropdown-with-default > .dropdown-action-container > .monaco-dropdown > .dropdown-label > .action-label {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  display: block;
}

.monaco-editor .zone-widget .zone-widget-container.reference-zone-widget {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.monaco-editor .reference-zone-widget .inline {
  vertical-align: top;
  display: inline-block;
}

.monaco-editor .reference-zone-widget .messages {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 3em 0;
}

.monaco-editor .reference-zone-widget .ref-tree {
  background-color: var(--vscode-peekViewResult-background);
  color: var(--vscode-peekViewResult-lineForeground);
  line-height: 23px;
}

.monaco-editor .reference-zone-widget .ref-tree .reference {
  text-overflow: ellipsis;
  overflow: hidden;
}

.monaco-editor .reference-zone-widget .ref-tree .reference-file {
  width: 100%;
  height: 100%;
  color: var(--vscode-peekViewResult-fileForeground);
  display: inline-flex;
}

.monaco-editor .reference-zone-widget .ref-tree .monaco-list:focus .selected .reference-file {
  color: inherit !important;
}

.monaco-editor .reference-zone-widget .ref-tree .monaco-list:focus .monaco-list-rows > .monaco-list-row.selected:not(.highlighted) {
  background-color: var(--vscode-peekViewResult-selectionBackground);
  color: var(--vscode-peekViewResult-selectionForeground) !important;
}

.monaco-editor .reference-zone-widget .ref-tree .reference-file .count {
  margin-left: auto;
  margin-right: 12px;
}

.monaco-editor .reference-zone-widget .ref-tree .referenceMatch .highlight {
  background-color: var(--vscode-peekViewResult-matchHighlightBackground);
}

.monaco-editor .reference-zone-widget .preview .reference-decoration {
  background-color: var(--vscode-peekViewEditor-matchHighlightBackground);
  border: 2px solid var(--vscode-peekViewEditor-matchHighlightBorder);
  box-sizing: border-box;
}

.monaco-editor .reference-zone-widget .preview .monaco-editor .monaco-editor-background, .monaco-editor .reference-zone-widget .preview .monaco-editor .inputarea.ime-input {
  background-color: var(--vscode-peekViewEditor-background);
}

.monaco-editor .reference-zone-widget .preview .monaco-editor .margin {
  background-color: var(--vscode-peekViewEditorGutter-background);
}

.monaco-editor.hc-black .reference-zone-widget .ref-tree .reference-file {
  font-weight: bold;
}

.monaco-editor.hc-black .reference-zone-widget .ref-tree .referenceMatch .highlight {
  border: 1px dotted var(--vscode-contrastActiveBorder, transparent);
  box-sizing: border-box;
}

.monaco-hover {
  cursor: default;
  z-index: 50;
  user-select: text;
  box-sizing: initial;
  line-height: 1.5em;
  animation: .1s linear fadein;
  position: absolute;
  overflow: hidden;
}

.monaco-hover.hidden {
  display: none;
}

.monaco-hover .hover-contents:not(.html-hover-contents) {
  padding: 4px 8px;
}

.monaco-hover .markdown-hover > .hover-contents:not(.code-hover-contents) {
  max-width: 500px;
  word-wrap: break-word;
}

.monaco-hover .markdown-hover > .hover-contents:not(.code-hover-contents) hr {
  min-width: 100%;
}

.monaco-hover p, .monaco-hover .code, .monaco-hover ul {
  margin: 8px 0;
}

.monaco-hover code {
  font-family: var(--monaco-monospace-font);
}

.monaco-hover hr {
  box-sizing: border-box;
  height: 1px;
  border-left: 0;
  border-right: 0;
  margin: 4px -8px -4px;
}

.monaco-hover p:first-child, .monaco-hover .code:first-child, .monaco-hover ul:first-child {
  margin-top: 0;
}

.monaco-hover p:last-child, .monaco-hover .code:last-child, .monaco-hover ul:last-child {
  margin-bottom: 0;
}

.monaco-hover ul, .monaco-hover ol {
  padding-left: 20px;
}

.monaco-hover li > p {
  margin-bottom: 0;
}

.monaco-hover li > ul {
  margin-top: 0;
}

.monaco-hover code {
  border-radius: 3px;
  padding: 0 .4em;
}

.monaco-hover .monaco-tokenized-source {
  white-space: pre-wrap;
}

.monaco-hover .hover-row.status-bar {
  font-size: 12px;
  line-height: 22px;
}

.monaco-hover .hover-row.status-bar .actions {
  padding: 0 8px;
  display: flex;
}

.monaco-hover .hover-row.status-bar .actions .action-container {
  cursor: pointer;
  margin-right: 16px;
}

.monaco-hover .hover-row.status-bar .actions .action-container .action .icon {
  padding-right: 4px;
}

.monaco-hover .markdown-hover .hover-contents .codicon {
  color: inherit;
  font-size: inherit;
  vertical-align: middle;
}

.monaco-hover .hover-contents a.code-link:hover, .monaco-hover .hover-contents a.code-link {
  color: inherit;
}

.monaco-hover .hover-contents a.code-link:before {
  content: "(";
}

.monaco-hover .hover-contents a.code-link:after {
  content: ")";
}

.monaco-hover .hover-contents a.code-link > span {
  text-underline-position: under;
  border-bottom: 1px solid #0000;
  text-decoration: underline;
}

.monaco-hover .markdown-hover .hover-contents:not(.code-hover-contents):not(.html-hover-contents) span {
  margin-bottom: 4px;
  display: inline-block;
}

.monaco-hover-content .action-container a {
  user-select: none;
}

.monaco-hover-content .action-container.disabled {
  pointer-events: none;
  opacity: .4;
  cursor: default;
}

.colorpicker-widget {
  height: 190px;
  user-select: none;
}

.monaco-editor .colorpicker-hover:focus {
  outline: none;
}

.colorpicker-color-decoration {
  box-sizing: border-box;
  width: .8em;
  height: .8em;
  border: .1em solid #000;
  margin: .1em .2em 0;
  line-height: .8em;
  display: inline-block;
}

.hc-black .colorpicker-color-decoration, .vs-dark .colorpicker-color-decoration {
  border: .1em solid #eee;
}

.colorpicker-header {
  height: 24px;
  image-rendering: pixelated;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAAAHUlEQVQYV2PYvXu3JAi7uLiAMaYAjAGTQBPYLQkAa/0Zef3qRswAAAAASUVORK5CYII=") 0 0 / 9px 9px;
  display: flex;
  position: relative;
}

.colorpicker-header .picked-color {
  width: 216px;
  cursor: pointer;
  color: #fff;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  display: flex;
}

.colorpicker-header .picked-color .codicon {
  color: inherit;
  font-size: 14px;
  position: absolute;
  left: 8px;
}

.colorpicker-header .picked-color.light {
  color: #000;
}

.colorpicker-header .original-color {
  width: 74px;
  z-index: inherit;
  cursor: pointer;
}

.colorpicker-body {
  padding: 8px;
  display: flex;
  position: relative;
}

.colorpicker-body .saturation-wrap {
  height: 150px;
  min-width: 220px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.colorpicker-body .saturation-box {
  height: 150px;
  position: absolute;
}

.colorpicker-body .saturation-selection {
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-radius: 100%;
  margin: -5px 0 0 -5px;
  position: absolute;
  box-shadow: 0 0 2px #000c;
}

.colorpicker-body .strip {
  width: 25px;
  height: 150px;
}

.colorpicker-body .hue-strip {
  cursor: grab;
  background: linear-gradient(red 0%, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red 100%);
  margin-left: 8px;
  position: relative;
}

.colorpicker-body .opacity-strip {
  cursor: grab;
  image-rendering: pixelated;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMTZEaa/1AAAAHUlEQVQYV2PYvXu3JAi7uLiAMaYAjAGTQBPYLQkAa/0Zef3qRswAAAAASUVORK5CYII=") 0 0 / 9px 9px;
  margin-left: 8px;
  position: relative;
}

.colorpicker-body .strip.grabbing {
  cursor: grabbing;
}

.colorpicker-body .slider {
  width: calc(100% + 4px);
  height: 4px;
  box-sizing: border-box;
  border: 1px solid #ffffffb5;
  position: absolute;
  top: 0;
  left: -2px;
  box-shadow: 0 0 1px #000000d9;
}

.colorpicker-body .strip .overlay {
  height: 150px;
  pointer-events: none;
}

.monaco-editor .peekview-widget .head .peekview-title .severity-icon {
  vertical-align: text-top;
  margin-right: 4px;
  display: inline-block;
}

.monaco-editor .marker-widget {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.monaco-editor .marker-widget > .stale {
  opacity: .6;
  font-style: italic;
}

.monaco-editor .marker-widget .title {
  padding-right: 5px;
  display: inline-block;
}

.monaco-editor .marker-widget .descriptioncontainer {
  white-space: pre;
  user-select: text;
  padding: 8px 12px 0 20px;
  position: absolute;
}

.monaco-editor .marker-widget .descriptioncontainer .message {
  flex-direction: column;
  display: flex;
}

.monaco-editor .marker-widget .descriptioncontainer .message .details {
  padding-left: 6px;
}

.monaco-editor .marker-widget .descriptioncontainer .message .source, .monaco-editor .marker-widget .descriptioncontainer .message span.code {
  opacity: .6;
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link {
  opacity: .6;
  color: inherit;
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link:before {
  content: "(";
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link:after {
  content: ")";
}

.monaco-editor .marker-widget .descriptioncontainer .message a.code-link > span {
  text-underline-position: under;
  color: var(--vscode-textLink-foreground);
  color: var(--vscode-textLink-activeForeground);
  border-bottom: 1px solid #0000;
  text-decoration: underline;
}

.monaco-editor .marker-widget .descriptioncontainer .filename {
  cursor: pointer;
}

.monaco-editor .snippet-placeholder {
  min-width: 2px;
  background-color: var(--vscode-editor-snippetTabstopHighlightBackground, transparent);
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--vscode-editor-snippetTabstopHighlightBorder, transparent);
}

.monaco-editor .finish-snippet-placeholder {
  background-color: var(--vscode-editor-snippetFinalTabstopHighlightBackground, transparent);
  outline-width: 1px;
  outline-style: solid;
  outline-color: var(--vscode-editor-snippetFinalTabstopHighlightBorder, transparent);
}

.monaco-editor .suggest-widget {
  width: 430px;
  z-index: 40;
  flex-direction: column;
  display: flex;
}

.monaco-editor .suggest-widget.message {
  flex-direction: row;
  align-items: center;
}

.monaco-editor .suggest-widget, .monaco-editor .suggest-details {
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: var(--vscode-editorSuggestWidget-border);
  background-color: var(--vscode-editorSuggestWidget-background);
  flex: 0 auto;
}

.monaco-editor.hc-black .suggest-widget, .monaco-editor.hc-black .suggest-details {
  border-width: 2px;
}

.monaco-editor .suggest-widget .suggest-status-bar {
  box-sizing: border-box;
  width: 100%;
  border-top: 1px solid var(--vscode-editorSuggestWidget-border);
  flex-flow: row;
  justify-content: space-between;
  padding: 0 4px;
  font-size: 80%;
  display: none;
  overflow: hidden;
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar {
  display: flex;
}

.monaco-editor .suggest-widget .suggest-status-bar .left {
  padding-right: 8px;
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar .action-label {
  color: var(--vscode-editorSuggestWidgetStatus-foreground);
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar .action-item:not(:last-of-type) .action-label {
  margin-right: 0;
}

.monaco-editor .suggest-widget.with-status-bar .suggest-status-bar .action-item:not(:last-of-type) .action-label:after {
  content: ", ";
  margin-right: .3em;
}

.monaco-editor .suggest-widget.with-status-bar .monaco-list .monaco-list-row > .contents > .main > .right > .readMore, .monaco-editor .suggest-widget.with-status-bar .monaco-list .monaco-list-row.focused.string-label > .contents > .main > .right > .readMore {
  display: none;
}

.monaco-editor .suggest-widget.with-status-bar:not(.docs-side) .monaco-list .monaco-list-row:hover > .contents > .main > .right.can-expand-details > .details-label {
  width: 100%;
}

.monaco-editor .suggest-widget > .message {
  padding-left: 22px;
}

.monaco-editor .suggest-widget > .tree {
  height: 100%;
  width: 100%;
}

.monaco-editor .suggest-widget .monaco-list {
  user-select: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row {
  -mox-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;
  touch-action: none;
  background-position: 2px 2px;
  background-repeat: no-repeat;
  padding-right: 10px;
  display: flex;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused {
  color: var(--vscode-editorSuggestWidget-selectedForeground);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused .codicon {
  color: var(--vscode-editorSuggestWidget-selectedIconForeground);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents {
  height: 100%;
  flex: 1;
  padding-left: 2px;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main {
  text-overflow: ellipsis;
  white-space: pre;
  justify-content: space-between;
  display: flex;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left, .monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right {
  display: flex;
}

.monaco-editor .suggest-widget:not(.frozen) .monaco-highlighted-label .highlight {
  font-weight: bold;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-highlighted-label .highlight {
  color: var(--vscode-editorSuggestWidget-highlightForeground);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused .monaco-highlighted-label .highlight {
  color: var(--vscode-editorSuggestWidget-focusHighlightForeground);
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .codicon-close, .monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .readMore:before {
  color: inherit;
  opacity: 1;
  cursor: pointer;
  font-size: 14px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .codicon-close {
  position: absolute;
  top: 6px;
  right: 2px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .codicon-close:hover, .monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .readMore:hover {
  opacity: 1;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label {
  opacity: .7;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left > .signature-label {
  text-overflow: ellipsis;
  opacity: .6;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left > .qualifier-label {
  opacity: .4;
  font-size: 85%;
  line-height: initial;
  text-overflow: ellipsis;
  align-self: center;
  margin-left: 12px;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 1.1em;
  font-size: 85%;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label > .monaco-tokenized-source {
  display: inline;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .details-label {
  display: none;
}

.monaco-editor .suggest-widget:not(.shows-details) .monaco-list .monaco-list-row.focused > .contents > .main > .right > .details-label, .monaco-editor .suggest-widget .monaco-list .monaco-list-row:not(.string-label) > .contents > .main > .right > .details-label, .monaco-editor .suggest-widget.docs-side .monaco-list .monaco-list-row.focused:not(.string-label) > .contents > .main > .right > .details-label {
  display: inline;
}

.monaco-editor .suggest-widget:not(.docs-side) .monaco-list .monaco-list-row:hover > .contents > .main > .right.can-expand-details > .details-label {
  width: calc(100% - 26px);
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .left > .monaco-icon-label {
  flex-shrink: 0;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row:not(.string-label) > .contents > .main > .left > .monaco-icon-label {
  max-width: 100%;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.string-label > .contents > .main > .left > .monaco-icon-label {
  flex-shrink: 1;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right {
  max-width: 70%;
  flex-shrink: 4;
  overflow: hidden;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .right > .readMore {
  width: 18px;
  height: 18px;
  visibility: hidden;
  display: inline-block;
  position: absolute;
  right: 10px;
}

.monaco-editor .suggest-widget.docs-side .monaco-list .monaco-list-row > .contents > .main > .right > .readMore {
  display: none !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.string-label > .contents > .main > .right > .readMore {
  display: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row.focused.string-label > .contents > .main > .right > .readMore {
  display: inline-block;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row:hover > .contents > .main > .right > .readMore {
  visibility: visible;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-icon-label.deprecated {
  opacity: .66;
  text-decoration: unset;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-icon-label.deprecated > .monaco-icon-label-container > .monaco-icon-name-container {
  text-decoration: line-through;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .monaco-icon-label:before {
  height: 100%;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .icon {
  height: 16px;
  width: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  margin-left: 2px;
  display: block;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .icon.hide {
  display: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon {
  align-items: center;
  margin-right: 4px;
  display: flex;
}

.monaco-editor .suggest-widget.no-icons .monaco-list .monaco-list-row .icon, .monaco-editor .suggest-widget.no-icons .monaco-list .monaco-list-row .suggest-icon:before {
  display: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .icon.customcolor .colorspan {
  width: .7em;
  height: .7em;
  border: .1em solid #000;
  margin: 0 0 0 .3em;
  display: inline-block;
}

.monaco-editor .suggest-details-container {
  z-index: 41;
}

.monaco-editor .suggest-details {
  cursor: default;
  color: var(--vscode-editorSuggestWidget-foreground);
  flex-direction: column;
  display: flex;
}

.monaco-editor .suggest-details.focused {
  border-color: var(--vscode-focusBorder);
}

.monaco-editor .suggest-details a {
  color: var(--vscode-textLink-foreground);
}

.monaco-editor .suggest-details a:hover {
  color: var(--vscode-textLink-activeForeground);
}

.monaco-editor .suggest-details code {
  background-color: var(--vscode-textCodeBlock-background);
}

.monaco-editor .suggest-details.no-docs {
  display: none;
}

.monaco-editor .suggest-details > .monaco-scrollable-element {
  flex: 1;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .type {
  text-overflow: ellipsis;
  opacity: .7;
  white-space: pre;
  flex: 2;
  margin: 0 24px 0 0;
  padding: 4px 0 12px 5px;
  overflow: hidden;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .header > .type.auto-wrap {
  white-space: normal;
  word-break: break-all;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs {
  white-space: pre-wrap;
  margin: 0;
  padding: 4px 5px;
}

.monaco-editor .suggest-details.no-type > .monaco-scrollable-element > .body > .docs {
  margin-right: 24px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs {
  white-space: initial;
  min-height: calc(1rem + 8px);
  padding: 0;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > div, .monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > span:not(:empty) {
  padding: 4px 5px;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > div > p:first-child {
  margin-top: 0;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs > div > p:last-child {
  margin-bottom: 0;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs .monaco-tokenized-source {
  white-space: pre;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs .code {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > .docs.markdown-docs .codicon {
  vertical-align: sub;
}

.monaco-editor .suggest-details > .monaco-scrollable-element > .body > p:empty {
  display: none;
}

.monaco-editor .suggest-details code {
  border-radius: 3px;
  padding: 0 .4em;
}

.monaco-editor .suggest-details ul, .monaco-editor .suggest-details ol {
  padding-left: 20px;
}

.monaco-editor .suggest-details p code {
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .suggest-preview-additional-widget {
  white-space: nowrap;
}

.monaco-editor .suggest-preview-additional-widget .content-spacer {
  color: #0000;
  white-space: pre;
}

.monaco-editor .suggest-preview-additional-widget .button {
  cursor: pointer;
  text-underline-position: under;
  text-decoration: underline;
  display: inline-block;
}

.monaco-editor .ghost-text-hidden {
  opacity: 0;
  font-size: 0;
}

.monaco-editor .ghost-text-decoration, .monaco-editor .suggest-preview-text {
  font-style: italic;
}

.monaco-editor .unicode-highlight {
  border: 1px solid var(--vscode-editorUnicodeHighlight-border);
  box-sizing: border-box;
}

.editor-banner {
  box-sizing: border-box;
  cursor: default;
  width: 100%;
  height: 26px;
  background: var(--vscode-banner-background);
  font-size: 12px;
  display: flex;
  overflow: visible;
}

.editor-banner .icon-container {
  flex-shrink: 0;
  align-items: center;
  padding: 0 6px 0 10px;
  display: flex;
}

.editor-banner .icon-container.custom-icon {
  width: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  margin: 0 6px 0 10px;
  padding: 0;
}

.editor-banner .message-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: center;
  line-height: 26px;
  display: flex;
  overflow: hidden;
}

.editor-banner .message-container p {
  margin-block: 0;
}

.editor-banner .message-actions-container {
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 4px;
  line-height: 26px;
}

.editor-banner .message-actions-container a.monaco-button {
  width: inherit;
  margin: 2px 8px;
  padding: 0 12px;
}

.editor-banner .message-actions-container a {
  margin-left: 12px;
  padding: 3px;
  text-decoration: underline;
}

.editor-banner .action-container {
  padding: 0 10px 0 6px;
}

.editor-banner {
  background-color: var(--vscode-banner-background);
}

.editor-banner, .editor-banner .action-container .codicon, .editor-banner .message-actions-container .monaco-link {
  color: var(--vscode-banner-foreground);
}

.editor-banner .icon-container .codicon {
  color: var(--vscode-banner-iconForeground);
}

.monaco-editor.vs .dnd-target {
  color: #fff;
  border-right: 2px dotted #000;
}

.monaco-editor.vs-dark .dnd-target {
  color: #51504f;
  border-right: 2px dotted #aeafad;
}

.monaco-editor.hc-black .dnd-target {
  color: #000;
  border-right: 2px dotted #fff;
}

.monaco-editor.mouse-default .view-lines, .monaco-editor.vs-dark.mac.mouse-default .view-lines, .monaco-editor.hc-black.mac.mouse-default .view-lines {
  cursor: default;
}

.monaco-editor.mouse-copy .view-lines, .monaco-editor.vs-dark.mac.mouse-copy .view-lines, .monaco-editor.hc-black.mac.mouse-copy .view-lines {
  cursor: copy;
}

.monaco-custom-checkbox {
  float: left;
  cursor: pointer;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  user-select: none;
  border: 1px solid #0000;
  border-radius: 3px;
  margin-left: 2px;
  padding: 1px;
  overflow: hidden;
}

.monaco-custom-checkbox:hover {
  background-color: var(--vscode-inputOption-hoverBackground);
}

.hc-black .monaco-custom-checkbox:hover {
  border: 1px dashed var(--vscode-focusBorder);
}

.hc-black .monaco-custom-checkbox, .hc-black .monaco-custom-checkbox:hover {
  background: none;
}

.monaco-custom-checkbox.monaco-simple-checkbox {
  height: 18px;
  width: 18px;
  opacity: 1;
  border: 1px solid #0000;
  border-radius: 3px;
  margin-left: 0;
  margin-right: 9px;
  padding: 0;
  background-size: 16px !important;
}

.monaco-custom-checkbox.monaco-simple-checkbox:not(.checked):before {
  visibility: hidden;
}

.monaco-editor .find-widget {
  z-index: 35;
  height: 33px;
  box-sizing: border-box;
  padding: 0 4px;
  line-height: 19px;
  transition: transform .2s linear;
  position: absolute;
  overflow: hidden;
  transform: translateY(calc(-100% - 10px));
}

.monaco-editor .find-widget textarea {
  margin: 0;
}

.monaco-editor .find-widget.hiddenEditor {
  display: none;
}

.monaco-editor .find-widget.replaceToggled > .replace-part {
  display: flex;
}

.monaco-editor .find-widget.visible {
  transform: translateY(0);
}

.monaco-editor .find-widget .monaco-inputbox.synthetic-focus {
  outline: 1px solid -webkit-focus-ring-color;
  outline-offset: -1px;
}

.monaco-editor .find-widget .monaco-inputbox .input {
  min-height: 0;
  background-color: #0000;
}

.monaco-editor .find-widget .monaco-findInput .input {
  font-size: 13px;
}

.monaco-editor .find-widget > .find-part, .monaco-editor .find-widget > .replace-part {
  margin: 4px 0 0 17px;
  font-size: 12px;
  display: flex;
}

.monaco-editor .find-widget > .find-part .monaco-inputbox, .monaco-editor .find-widget > .replace-part .monaco-inputbox {
  min-height: 25px;
}

.monaco-editor .find-widget > .replace-part .monaco-inputbox > .ibwrapper > .mirror {
  padding-right: 22px;
}

.monaco-editor .find-widget > .find-part .monaco-inputbox > .ibwrapper > .input, .monaco-editor .find-widget > .find-part .monaco-inputbox > .ibwrapper > .mirror, .monaco-editor .find-widget > .replace-part .monaco-inputbox > .ibwrapper > .input, .monaco-editor .find-widget > .replace-part .monaco-inputbox > .ibwrapper > .mirror {
  padding-top: 2px;
  padding-bottom: 2px;
}

.monaco-editor .find-widget > .find-part .find-actions, .monaco-editor .find-widget > .replace-part .replace-actions {
  height: 25px;
  align-items: center;
  display: flex;
}

.monaco-editor .find-widget .monaco-findInput {
  vertical-align: middle;
  flex: 1;
  display: flex;
}

.monaco-editor .find-widget .monaco-findInput .monaco-scrollable-element {
  width: 100%;
}

.monaco-editor .find-widget .monaco-findInput .monaco-scrollable-element .scrollbar.vertical {
  opacity: 0;
}

.monaco-editor .find-widget .matchesCount {
  flex: initial;
  height: 25px;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: center;
  margin: 0 0 0 3px;
  padding: 2px 0 0 2px;
  line-height: 23px;
  display: flex;
}

.monaco-editor .find-widget .button {
  width: 16px;
  height: 16px;
  flex: initial;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  padding: 3px;
  display: flex;
}

.monaco-editor .find-widget .codicon-find-selection {
  width: 22px;
  height: 22px;
  border-radius: 5px;
  padding: 3px;
}

.monaco-editor .find-widget .button.left {
  margin-left: 0;
  margin-right: 3px;
}

.monaco-editor .find-widget .button.wide {
  width: auto;
  padding: 1px 6px;
  top: -1px;
}

.monaco-editor .find-widget .button.toggle {
  width: 18px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 0;
  position: absolute;
  top: 0;
  left: 3px;
}

.monaco-editor .find-widget .button.toggle.disabled {
  display: none;
}

.monaco-editor .find-widget .disabled {
  opacity: .3;
  cursor: default;
}

.monaco-editor .find-widget > .replace-part {
  display: none;
}

.monaco-editor .find-widget > .replace-part > .monaco-findInput {
  vertical-align: middle;
  flex: none;
  display: flex;
  position: relative;
}

.monaco-editor .find-widget > .replace-part > .monaco-findInput > .controls {
  position: absolute;
  top: 3px;
  right: 2px;
}

.monaco-editor .find-widget.reduced-find-widget .matchesCount {
  display: none;
}

.monaco-editor .find-widget.narrow-find-widget {
  max-width: 257px !important;
}

.monaco-editor .find-widget.collapsed-find-widget {
  max-width: 170px !important;
}

.monaco-editor .find-widget.collapsed-find-widget .button.previous, .monaco-editor .find-widget.collapsed-find-widget .button.next, .monaco-editor .find-widget.collapsed-find-widget .button.replace, .monaco-editor .find-widget.collapsed-find-widget .button.replace-all, .monaco-editor .find-widget.collapsed-find-widget > .find-part .monaco-findInput .controls {
  display: none;
}

.monaco-editor .findMatch {
  animation-duration: 0;
  animation-name: inherit !important;
}

.monaco-editor .find-widget .monaco-sash {
  left: 0 !important;
}

.monaco-editor.hc-black .find-widget .button:before {
  position: relative;
  top: 1px;
  left: 2px;
}

.monaco-findInput {
  position: relative;
}

.monaco-findInput .monaco-inputbox {
  width: 100%;
  font-size: 13px;
}

.monaco-findInput > .controls {
  position: absolute;
  top: 3px;
  right: 2px;
}

.vs .monaco-findInput.disabled {
  background-color: #e1e1e1;
}

.vs-dark .monaco-findInput.disabled {
  background-color: #333;
}

.monaco-findInput.highlight-0 .controls {
  animation: .1s linear monaco-findInput-highlight-0;
}

.monaco-findInput.highlight-1 .controls {
  animation: .1s linear monaco-findInput-highlight-1;
}

.hc-black .monaco-findInput.highlight-0 .controls, .vs-dark .monaco-findInput.highlight-0 .controls {
  animation: .1s linear monaco-findInput-highlight-dark-0;
}

.hc-black .monaco-findInput.highlight-1 .controls, .vs-dark .monaco-findInput.highlight-1 .controls {
  animation: .1s linear monaco-findInput-highlight-dark-1;
}

@keyframes monaco-findInput-highlight-0 {
  0% {
    background: #fdff00cc;
  }

  100% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-1 {
  0% {
    background: #fdff00cc;
  }

  99% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-dark-0 {
  0% {
    background: #ffffff70;
  }

  100% {
    background: none;
  }
}

@keyframes monaco-findInput-highlight-dark-1 {
  0% {
    background: #ffffff70;
  }

  99% {
    background: none;
  }
}

.monaco-editor .margin-view-overlays .codicon-folding-expanded, .monaco-editor .margin-view-overlays .codicon-folding-collapsed {
  cursor: pointer;
  opacity: 0;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  font-size: 140%;
  transition: opacity .5s;
  display: flex;
}

.monaco-editor .margin-view-overlays:hover .codicon, .monaco-editor .margin-view-overlays .codicon.codicon-folding-collapsed, .monaco-editor .margin-view-overlays .codicon.alwaysShowFoldIcons {
  opacity: 1;
}

.monaco-editor .inline-folded:after {
  color: gray;
  content: "⋯";
  cursor: pointer;
  margin: .1em .2em 0;
  line-height: 1em;
  display: inline;
}

.monaco-editor .detected-link, .monaco-editor .detected-link-active {
  text-underline-position: under;
  text-decoration: underline;
}

.monaco-editor .detected-link-active {
  cursor: pointer;
}

.monaco-editor .parameter-hints-widget {
  z-index: 10;
  flex-direction: column;
  line-height: 1.5em;
  display: flex;
}

.monaco-editor .parameter-hints-widget > .phwrapper {
  max-width: 440px;
  flex-direction: row;
  display: flex;
}

.monaco-editor .parameter-hints-widget.multiple {
  min-height: 3.3em;
  padding: 0;
}

.monaco-editor .parameter-hints-widget.visible {
  transition: left 50ms ease-in-out;
}

.monaco-editor .parameter-hints-widget p, .monaco-editor .parameter-hints-widget ul {
  margin: 8px 0;
}

.monaco-editor .parameter-hints-widget .monaco-scrollable-element, .monaco-editor .parameter-hints-widget .body {
  min-height: 100%;
  flex-direction: column;
  flex: 1;
  display: flex;
}

.monaco-editor .parameter-hints-widget .signature {
  padding: 4px 5px;
}

.monaco-editor .parameter-hints-widget .docs {
  white-space: pre-wrap;
  padding: 0 10px 0 5px;
}

.monaco-editor .parameter-hints-widget .docs.empty {
  display: none;
}

.monaco-editor .parameter-hints-widget .docs .markdown-docs {
  white-space: initial;
}

.monaco-editor .parameter-hints-widget .docs .markdown-docs code {
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .parameter-hints-widget .docs .code {
  white-space: pre-wrap;
}

.monaco-editor .parameter-hints-widget .docs code {
  border-radius: 3px;
  padding: 0 .4em;
}

.monaco-editor .parameter-hints-widget .controls {
  min-width: 22px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  display: none;
}

.monaco-editor .parameter-hints-widget.multiple .controls {
  padding: 0 2px;
  display: flex;
}

.monaco-editor .parameter-hints-widget.multiple .button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-repeat: no-repeat;
}

.monaco-editor .parameter-hints-widget .button.previous {
  bottom: 24px;
}

.monaco-editor .parameter-hints-widget .overloads {
  text-align: center;
  height: 12px;
  opacity: .5;
  line-height: 12px;
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .parameter-hints-widget .signature .parameter.active {
  font-weight: bold;
}

.monaco-editor .parameter-hints-widget .documentation-parameter > .parameter {
  margin-right: .5em;
  font-weight: bold;
}

.monaco-editor .rename-box {
  z-index: 100;
  color: inherit;
}

.monaco-editor .rename-box.preview {
  padding: 3px 3px 0;
}

.monaco-editor .rename-box .rename-input {
  width: calc(100% - 6px);
  padding: 3px;
}

.monaco-editor .rename-box .rename-label {
  opacity: .8;
  display: none;
}

.monaco-editor .rename-box.preview .rename-label {
  display: inherit;
}

.monaco-editor .accessibilityHelpWidget {
  vertical-align: middle;
  padding: 10px;
  overflow: scroll;
}

.monaco-editor .iPadShowKeyboard {
  width: 58px;
  min-width: 0;
  height: 36px;
  min-height: 0;
  resize: none;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1MyAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDguMDM2NCA0LjAxMDQySDQuMDA3NzlMNC4wMDc3OSAzMi4wMjg2SDQ4LjAzNjRWNC4wMTA0MlpNNC4wMDc3OSAwLjAwNzgxMjVDMS43OTcyMSAwLjAwNzgxMjUgMC4wMDUxODc5OSAxLjc5OTg0IDAuMDA1MTg3OTkgNC4wMTA0MlYzMi4wMjg2QzAuMDA1MTg3OTkgMzQuMjM5MiAxLjc5NzIxIDM2LjAzMTIgNC4wMDc3OSAzNi4wMzEySDQ4LjAzNjRDNTAuMjQ3IDM2LjAzMTIgNTIuMDM5IDM0LjIzOTIgNTIuMDM5IDMyLjAyODZWNC4wMTA0MkM1Mi4wMzkgMS43OTk4NCA1MC4yNDcgMC4wMDc4MTI1IDQ4LjAzNjQgMC4wMDc4MTI1SDQuMDA3NzlaTTguMDEwNDIgOC4wMTMwMkgxMi4wMTNWMTIuMDE1Nkg4LjAxMDQyVjguMDEzMDJaTTIwLjAxODIgOC4wMTMwMkgxNi4wMTU2VjEyLjAxNTZIMjAuMDE4MlY4LjAxMzAyWk0yNC4wMjA4IDguMDEzMDJIMjguMDIzNFYxMi4wMTU2SDI0LjAyMDhWOC4wMTMwMlpNMzYuMDI4NiA4LjAxMzAySDMyLjAyNlYxMi4wMTU2SDM2LjAyODZWOC4wMTMwMlpNNDAuMDMxMiA4LjAxMzAySDQ0LjAzMzlWMTIuMDE1Nkg0MC4wMzEyVjguMDEzMDJaTTE2LjAxNTYgMTYuMDE4Mkg4LjAxMDQyVjIwLjAyMDhIMTYuMDE1NlYxNi4wMTgyWk0yMC4wMTgyIDE2LjAxODJIMjQuMDIwOFYyMC4wMjA4SDIwLjAxODJWMTYuMDE4MlpNMzIuMDI2IDE2LjAxODJIMjguMDIzNFYyMC4wMjA4SDMyLjAyNlYxNi4wMTgyWk00NC4wMzM5IDE2LjAxODJWMjAuMDIwOEgzNi4wMjg2VjE2LjAxODJINDQuMDMzOVpNMTIuMDEzIDI0LjAyMzRIOC4wMTA0MlYyOC4wMjZIMTIuMDEzVjI0LjAyMzRaTTE2LjAxNTYgMjQuMDIzNEgzNi4wMjg2VjI4LjAyNkgxNi4wMTU2VjI0LjAyMzRaTTQ0LjAzMzkgMjQuMDIzNEg0MC4wMzEyVjI4LjAyNkg0NC4wMzM5VjI0LjAyMzRaIiBmaWxsPSIjNDI0MjQyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==") center no-repeat;
  border: 4px solid #f6f6f6;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.monaco-editor.vs-dark .iPadShowKeyboard {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCA1MyAzNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDguMDM2NCA0LjAxMDQySDQuMDA3NzlMNC4wMDc3OSAzMi4wMjg2SDQ4LjAzNjRWNC4wMTA0MlpNNC4wMDc3OSAwLjAwNzgxMjVDMS43OTcyMSAwLjAwNzgxMjUgMC4wMDUxODc5OSAxLjc5OTg0IDAuMDA1MTg3OTkgNC4wMTA0MlYzMi4wMjg2QzAuMDA1MTg3OTkgMzQuMjM5MiAxLjc5NzIxIDM2LjAzMTIgNC4wMDc3OSAzNi4wMzEySDQ4LjAzNjRDNTAuMjQ3IDM2LjAzMTIgNTIuMDM5IDM0LjIzOTIgNTIuMDM5IDMyLjAyODZWNC4wMTA0MkM1Mi4wMzkgMS43OTk4NCA1MC4yNDcgMC4wMDc4MTI1IDQ4LjAzNjQgMC4wMDc4MTI1SDQuMDA3NzlaTTguMDEwNDIgOC4wMTMwMkgxMi4wMTNWMTIuMDE1Nkg4LjAxMDQyVjguMDEzMDJaTTIwLjAxODIgOC4wMTMwMkgxNi4wMTU2VjEyLjAxNTZIMjAuMDE4MlY4LjAxMzAyWk0yNC4wMjA4IDguMDEzMDJIMjguMDIzNFYxMi4wMTU2SDI0LjAyMDhWOC4wMTMwMlpNMzYuMDI4NiA4LjAxMzAySDMyLjAyNlYxMi4wMTU2SDM2LjAyODZWOC4wMTMwMlpNNDAuMDMxMiA4LjAxMzAySDQ0LjAzMzlWMTIuMDE1Nkg0MC4wMzEyVjguMDEzMDJaTTE2LjAxNTYgMTYuMDE4Mkg4LjAxMDQyVjIwLjAyMDhIMTYuMDE1NlYxNi4wMTgyWk0yMC4wMTgyIDE2LjAxODJIMjQuMDIwOFYyMC4wMjA4SDIwLjAxODJWMTYuMDE4MlpNMzIuMDI2IDE2LjAxODJIMjguMDIzNFYyMC4wMjA4SDMyLjAyNlYxNi4wMTgyWk00NC4wMzM5IDE2LjAxODJWMjAuMDIwOEgzNi4wMjg2VjE2LjAxODJINDQuMDMzOVpNMTIuMDEzIDI0LjAyMzRIOC4wMTA0MlYyOC4wMjZIMTIuMDEzVjI0LjAyMzRaTTE2LjAxNTYgMjQuMDIzNEgzNi4wMjg2VjI4LjAyNkgxNi4wMTU2VjI0LjAyMzRaTTQ0LjAzMzkgMjQuMDIzNEg0MC4wMzEyVjI4LjAyNkg0NC4wMzM5VjI0LjAyMzRaIiBmaWxsPSIjQzVDNUM1Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDAiPgo8cmVjdCB3aWR0aD0iNTMiIGhlaWdodD0iMzYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==") center no-repeat;
  border: 4px solid #252526;
}

.monaco-editor .tokens-inspect-widget {
  z-index: 50;
  user-select: text;
  padding: 10px;
}

.tokens-inspect-separator {
  height: 1px;
  border: 0;
}

.monaco-editor .tokens-inspect-widget .tm-token {
  font-family: var(--monaco-monospace-font);
}

.monaco-editor .tokens-inspect-widget .tm-token-length {
  float: right;
  font-size: 60%;
  font-weight: normal;
}

.monaco-editor .tokens-inspect-widget .tm-metadata-table {
  width: 100%;
}

.monaco-editor .tokens-inspect-widget .tm-metadata-value {
  font-family: var(--monaco-monospace-font);
  text-align: right;
}

.monaco-editor .tokens-inspect-widget .tm-token-type {
  font-family: var(--monaco-monospace-font);
}

.code-editor {
  height: 100%;
}



.editor-preview {
  height: 100%;
  overflow-y: auto;
}

.editor-body-viewer {
  width: 432px;
  overflow-y: auto;
}

.excalidraw-code-modal-preview {
  background-color: #272822;
  font-size: 24px;
  top: -99999px;
}

.excalidraw-code-modal-preview pre {
  margin: 0 !important;
}

.excalidraw label[title="Library"] {
  display: none;
}

.excalidraw-stage-ratio {
  width: 60%;
  border: 1px dashed #999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/*# sourceMappingURL=index.c6ca4bcb.css.map */
