.template-default-middle {
  --primary-color: #ffa500;

  display: flex;
  align-items: stretch;

  background-color: #fff;
  width: 1080px;
  height: 1080px;
  padding: 68px 32px 68px 68px;

  &-body {
    margin-right: 68px;
    flex: 1;
    overflow: hidden;

    &-logo {
      width: 96px;
      height: 58px;
    }

    &-content {
      font-family: 'Helvetica Neue';
      font-size: 40px;
      line-height: 64px;

      > * {
        margin-top: 36px;
      }

      h2 {
        margin: 64px 0;
        display: inline-block;
        border: 1px solid #000;
        padding: 10px 20px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        font-size: 48px;
        font-family: 'Impact';
        font-weight: normal;

        + * {
          margin-top: 0;
        }
      }

      h3 {
        margin: 48px 0;
        display: inline-block;
        border: 1px solid #000;
        padding: 4px 16px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
        font-size: 36px;
        font-family: 'Impact';
        font-weight: normal;

        + * {
          margin-top: 0;
        }
      }

      em {
        opacity: 0.6;
        font-style: italic;
      }

      strong {
        font-weight: bold;
        color: var(--primary-color);
      }

      img {
        display: block;
        width: 100%;
      }

      ol {
        padding-left: 1.2em;
        list-style-type: decimal;

        > li {
          &:nth-child(n + 2) {
            margin-top: 12px;
          }

          &::marker {
            color: var(--theme-color);
          }
        }
      }

      ul {
        padding-left: 1em;
        list-style-type: initial;

        > li {
          &::marker {
            color: var(--theme-color);
          }
        }
      }
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.4;

    &-progress {
      flex: 1;
      border: 1px solid var(--theme-color);
      width: 8px;

      &-scroller {
        background-color: var(--theme-color);
      }
    }

    &-pager {
      margin-top: 34px;
      font-family: 'Impact';
      font-weight: normal;
      font-size: 48px;
      color: var(--theme-color);
    }
  }
}
