.template-default-first {
  --primary-color: #ffa500;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--theme-color) center center no-repeat;
  width: 1080px;
  height: 1080px;
  padding: 68px 84px;

  &-content {
    font-family: 'Helvetica Neue';
    font-size: 36px;
    line-height: 64px;
    color: #fff;

    h1 {
      font-family: 'Impact';
      font-weight: normal;
      font-size: 100px;
      line-height: 1.5;

      strong {
        font-weight: normal;
        color: var(--primary-color);
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
