.excalidraw-code-modal {
  &-preview {
    font-size: 24px;
    background-color: #272822;
    top: -99999px;

    pre {
      margin: 0 !important;
    }
  }
}
