.markdown-display {
  &-monospace {
    border-radius: 8px;
    border: 4px solid #ccc;
    padding: 8px 12px;
    vertical-align: 2px;
    color: #fff;
  }

  &-code {
    border-radius: 8px !important;
    padding: 12px 20px !important;
    font-size: 30px !important;
    line-height: 1.8 !important;
  }
}
