.template-default-last {
  --primary-color: #ffa500;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--theme-color) center center no-repeat;
  width: 1080px;
  height: 1080px;
  padding: 68px 136px;
}
