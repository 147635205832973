@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn {
    @apply py-2 px-4 rounded-lg hover:opacity-60;
  }
  .btn-default {
    @apply bg-gray-100;
  }
  .btn-danger {
    @apply text-white bg-red-500;
  }
  .btn-white {
    @apply border-gray-100 border-2;
  }
  .btn-primary {
    @apply text-white bg-orange-400;
  }
}
